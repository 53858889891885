import axios, { AxiosRequestConfig } from 'axios';
import { ErrorTypes, GenericError } from 'models';
import { getToken } from '../../services/auth-service';
import { env } from 'env';
import { localStorageClient } from 'clients/local-storage-client';

const baseUrl = env.REACT_APP_API_URL || '/api';

const instance = axios.create({
  baseURL: `${baseUrl}/`,
});

export enum ApiErrorTypes {
  NetworkError = 'NETWORK_ERROR',
  NotFound = 'NOT_FOUND',
  Forbidden = 'FORBIDDEN',
  InvalidCredentials = 'INVALID_CREDENTIALS',
}

export async function appendAuthToken(config: AxiosRequestConfig) {
  const authToken = await getToken();
  await localStorageClient.setItem<Date>('auth:lastApiCall', new Date());

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(!!authToken && {
        Authorization: `Bearer ${authToken}`,
      }),
    },
  };
}

export function rejectApiError(error: any) {
  const { message, response, config: { url = null } = {} } = error;

  const { status = null, data } = response || {};

  let returnError: GenericError;

  if (!response && message === 'Network Error') {
    // network error
    returnError = new GenericError(
      ApiErrorTypes.NetworkError,
      data?.message ?? message,
      response,
    );
  } else if (!!status && [401, 404, 403].includes(status)) {
    const mapping: Record<number, string> = {
      401: ApiErrorTypes.InvalidCredentials,
      403: ApiErrorTypes.Forbidden,
      404: ApiErrorTypes.NotFound,
    };
    returnError = new GenericError(
      mapping[status],
      data?.message ?? message,
      response,
    );
  } else if (!!data && !!('code' in data)) {
    const { code, message } = data;
    returnError = new GenericError(code, message, response);
  } else {
    returnError = new GenericError(
      ErrorTypes.Unknown,
      data?.message ?? message,
      response,
    );
  }

  return Promise.reject(returnError);
}

instance.interceptors.request.use(async (config) => {
  return await appendAuthToken(config);
});

instance.interceptors.response.use(
  (response) => response,
  (error) => rejectApiError(error),
);
export const apiClient = instance;
