import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import donorsSlice from './slices/donors';
import userssSlice from './slices/users';
import errorsSlice from './errors';
import testsSlice from './slices/tests';
import testsOnSiteSlice from './slices/tests-onsite';
import { resetStore } from './actions';
import scheduledCollectionsSlice from './slices/scheduled-collections';
import agenciesSlice from './slices/agencies';
import resultsSlice from './slices/results';
import pendingSpecimenSlice from './slices/pending-specimens';
import collectionSitesSlice from './slices/collection-sites';
import collectionTypesSlice from './slices/collection-types';
import orderingPractitionersSlice from './slices/ordering-practitioners';
import specimenTypesSlice from './slices/specimen-types';
import operationsSlice from './slices/operations';
import collectionSlice from './slices/collections';
import donorScheduledCollectionsSlice from './slices/donor-scheduled-collections';
import collectorsSlice from './slices/collectors';
import cancellationReasonsSlice from './slices/cancellation-reasons';
import agencyGroupSettingsSlice from './slices/agency-group-settings';

const appReducer = combineReducers({
  users: userssSlice,
  donors: donorsSlice,
  errors: errorsSlice,
  scheduledCollections: scheduledCollectionsSlice,
  results: resultsSlice,
  pendingSpecimens: pendingSpecimenSlice,
  agencies: agenciesSlice,
  collectionSites: collectionSitesSlice,
  collectionTypes: collectionTypesSlice,
  orderingPractitioners: orderingPractitionersSlice,
  specimenTypes: specimenTypesSlice,
  operations: operationsSlice,
  tests: testsSlice,
  testsOnSite: testsOnSiteSlice,
  collections: collectionSlice,
  donorScheduledCollections: donorScheduledCollectionsSlice,
  collectors: collectorsSlice,
  cancellationReasons: cancellationReasonsSlice,
  agencyGroupSettings: agencyGroupSettingsSlice,
});

export const rootReducer: Reducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === resetStore.type) {
    return appReducer(undefined, { type: undefined });
  }
  return appReducer(state, action);
};

export default rootReducer;
