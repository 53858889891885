import axios from 'axios';
import { appendAuthToken, rejectApiError } from '../api-client';
import { GenericError } from 'models';
import { env } from 'env';

export enum AuthErrorTypes {
  AccountLocked = 'USER_LOCKED',
  AccountDisabled = 'USER_DISABLED',
  MaxAttemptsExceeded = 'MAX_LOGGIN_ATTEMPTS_EXCEEDED',
  MultipleSessions = 'MULTIPLE_SESSIONS',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  PasswordChange = 'PASSWORD_CHANGE',
  LoginAtemptInvalid = 'LOGIN_ATTEMPT_INVALID',
}

const baseUrl = env.REACT_APP_API_URL || '/api';

const instance = axios.create({
  baseURL: `${baseUrl}/UserManagement`,
});

instance.interceptors.request.use(async (config) => {
  if (config.url !== '/login') {
    return await appendAuthToken(config);
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // intercept errors
    const {
      response: { status = null, data = null } = {},
      config: { url = null } = {},
    } = error;

    const { errorMessage, message } = data || {};

    if (url === '/login') {
      let errorInstance;
      if (status === 401) {
        errorInstance = new GenericError(
          AuthErrorTypes.LoginAtemptInvalid,
          message,
        );
      } else if (status === 423) {
        if (Object.values(AuthErrorTypes).includes(errorMessage)) {
          errorInstance = new GenericError(errorMessage, message);
        } else {
          errorInstance = error;
        }
      } else if (status === 409) {
        errorInstance = new GenericError(
          AuthErrorTypes.MultipleSessions,
          message,
        );
      }
      if (errorInstance) {
        return Promise.reject(errorInstance);
      }
      return Promise.reject(errorInstance);
    } else if (
      url === '/changepassword' ||
      url === '/forgotpassword' ||
      url === '/resetpassword'
    ) {
      const errorInstance = new GenericError(errorMessage, message);
      if (errorInstance) {
        return Promise.reject(errorInstance);
      } else {
        return Promise.reject(error);
      }
    }
    return rejectApiError(error);
  },
);

export const authClient = instance;
