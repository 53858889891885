import { AnyAction, Draft } from '@reduxjs/toolkit';
import { filterUnique } from 'utils/array-utils';

import {
  EntityState,
  FetchListArgs,
  ListState,
  StateSlice,
  StoreStatus,
  WithId,
} from './types';

export function getListNameFor({ list, filter }: Partial<FetchListArgs>) {
  let name = list ?? 'all';
  if (!!filter?.length) {
    name = `${name}?filter=${filter.toLocaleLowerCase()}`;
  }
  return name;
}

function parseListState<T extends WithId>(
  state: StateSlice<T>,
  list: string,
  { ids, meta, status = StoreStatus.Idle, error }: Partial<ListState<T>>,
  incremental: boolean = false,
) {
  return {
    ...state.byList,
    [list]: {
      ...state.byList[list],
      status,
      ...(!!ids && {
        ids,
      }),
      ...(!!meta && {
        meta: {
          ...state.byList[list].meta,
          ...meta,
        },
      }),
      error,
    },
  };
}

export function addItemToState<T extends WithId>(
  state: Draft<StateSlice<T>>,
  { data, status = StoreStatus.Idle, error }: Partial<EntityState<T>>,
) {
  type idT = T['id'];
  const { id, ...idlessData } = data as WithId;
  if (!!id || id === 0) {
    (state as StateSlice<T>).byId = {
      ...(state.byId as Record<T['id'], EntityState<T>>),
      [id]: {
        status,
        data: {
          ...(state.byId as Record<T['id'], EntityState<T>>)[id as idT]?.data,
          ...idlessData,
        },
        error,
      },
    } as Record<T['id'], EntityState<T>>;
  }
}

export function addItemsToState<T extends WithId>(
  state: Draft<StateSlice<T>>,
  items: T[],
  status: StoreStatus = StoreStatus.Idle,
) {
  state.byId = {
    ...(state.byId as Record<T['id'], EntityState<T>>),
    ...items.reduce(
      (acc: any, { id, ...data }: any) => ({
        ...acc,
        [id]: {
          status,
          data,
        },
      }),
      {},
    ),
  };
}

export function addListToState<T extends WithId>(
  state: Draft<StateSlice<T>>,
  list: string,
  { ids, meta, status = StoreStatus.Idle, error }: Partial<ListState<T>>,
  incremental: boolean = true,
) {
  type idT = T['id'];
  let newIds = ids;
  if (incremental && !!ids?.length) {
    newIds = filterUnique<idT>([...(state.byList[list]?.ids ?? []), ...ids]);
  }
  // @ts-ignore
  state.byList = parseListState<T, idT>(
    state,
    list,
    {
      ids: newIds,
      meta,
      status,
      error,
    },
    incremental,
  );
}

export function createInitialSlice<T extends WithId>(
  status: StoreStatus = StoreStatus.Idle,
): StateSlice<T> {
  return {
    byId: {} as any,
    byList: {
      all: {
        status: StoreStatus.Loading,
        ids: [],
      },
    },
    drafts: [],
  };
}

//StatefulAction extends AnyAction
export function createStatefulMatcher<StatefulAction extends AnyAction>(
  sliceName: string,
  state: 'pending' | 'fulfilled' | 'rejected',
) {
  return (action: AnyAction): action is StatefulAction => {
    return (
      action.type?.startsWith(`${sliceName}/`) &&
      action.type?.endsWith(`/${state}`)
    );
  };
}
