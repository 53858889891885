import { PaginationArgs } from 'store/types';
import { normalizeError } from 'store/errors';
import { apiClient } from 'clients';
import {
  ErrorTypes,
  GenericError,
  OrderTest,
  TestCodesValidation,
  OnSiteTest,
  Test,
} from 'models';
import { FetchListArgs, GenericListResult } from './types';
import { handleFetchError } from 'utils/error-utils';

export interface TestListResult extends GenericListResult<OrderTest> {}

export interface OnSiteTestResult extends GenericListResult<OnSiteTest> {}

export interface OrderTestsArg extends FetchListArgs, PaginationArgs {
  agencyCode?: number;
  donorId?: number;
  isConfirmReditest?: boolean;
}

export interface OtherTestsArg extends FetchListArgs, PaginationArgs {
  userAgencyId?: number;
  agencyCode?: number;
}

export interface TestCodeArg {
  testCode: string;
}

export interface TestCodesValidationArg {
  agencyCode: number;
  tests: TestCodeArg[];
}

export interface OnSiteScheduledTestsArg extends FetchListArgs {
  agencyCode: number;
  donorId: number;
  scheduledDate?: string;
}

export interface OnSiteTestsDevicesArg extends FetchListArgs {
  agencyCode: number;
}

export interface OnSiteTestsDevicesResultsArg extends FetchListArgs {
  agencyCode: number;
  deviceId: number;
}

export async function getOrderTestsAsync(
  args: OrderTestsArg,
): Promise<TestListResult> {
  try {
    const { data } = await apiClient.get(
      `/Tests/${args.agencyCode}/OrderTests/Donors/${args.donorId}${
        args.isConfirmReditest
          ? `?isConfirmReditest=${args.isConfirmReditest}`
          : ''
      }`,
    );
    const { data: OrderTestList } = data;

    const items = OrderTestList.map(
      ({
        testCode,
        agencyCode,
        isRoutine,
        description,
        onLabel,
        defaultTestCode,
        specimenType,
        defaultLabSpecimenType,
        specimenTypeDescription,
      }: any) =>
        ({
          id: testCode,
          testCode,
          description,
          agencyCode,
          isRoutine,
          onLabel: !!onLabel,
          defaultTestCode,
          specimenType,
          defaultLabSpecimenType,
          specimenTypeDescription,
        } as OrderTest),
    );

    return {
      items,
    };
  } catch (error: any) {
    if ('code' in error) {
      throw normalizeError(error);
    } else {
      throw normalizeError(
        new GenericError(ErrorTypes.Unknown, error.message ?? ''),
      );
    }
  }
}

export async function getOtherTestsAsync(
  args: OtherTestsArg,
): Promise<TestListResult> {
  try {
    const { data } = await apiClient.get(
      `/AgencyGroups/${args.userAgencyId}/Agencies/${args.agencyCode}/OtherTest`,
    );
    const { data: OrderTestList } = data;

    const items = OrderTestList.map(
      ({
        testCode,
        agencyCode,
        isRoutine,
        description,
        onLabel,
        defaultTestCode,
        specimenType,
        defaultLabSpecimenType,
        specimenTypeDescription,
      }: any) =>
        ({
          id: testCode,
          testCode,
          description,
          agencyCode,
          isRoutine,
          onLabel: !!onLabel,
          defaultTestCode,
          specimenType,
          defaultLabSpecimenType,
          specimenTypeDescription,
        } as OrderTest),
    );

    return {
      items,
    };
  } catch (error: any) {
    if ('code' in error) {
      throw normalizeError(error);
    } else {
      throw normalizeError(
        new GenericError(ErrorTypes.Unknown, error.message ?? ''),
      );
    }
  }
}

export async function postTestCodesValidationAsync(
  args: TestCodesValidationArg,
): Promise<TestCodesValidation> {
  try {
    const { data } = await apiClient.post(
      `/AgencyGroups/${args.agencyCode}/TestCodes/Validation`,
      args.tests,
    );
    const { data: testCodesValidation } = data;

    return testCodesValidation as TestCodesValidation;
  } catch (error: any) {
    const { status, data: testCodesValidation } = error.response;
    if (status === 400) {
      return testCodesValidation.data as TestCodesValidation;
    } else {
      throw handleFetchError(error);
    }
  }
}

export async function getOnSiteScheduledTestsDevicesAsync(
  args: OnSiteScheduledTestsArg,
): Promise<OnSiteTestResult> {
  try {
    const { data } = await apiClient.get(
      `/Tests/${args.agencyCode}/Devices/ScheduledDevices`,
      { params: { donorId: args.donorId, scheduledDate: args.scheduledDate } },
    );

    const {
      data: {
        agencyCode: agencyCode,
        testCode: id,
        isRoutine,
        description,
        defaultTestcode,
        specimenType,
        specimenTypeDescription,
        defaultLabSpecimenType,
        isOnsite,
        testResult,
      },
    } = data;

    const items = [
      {
        id,
        agencyCode,
        testCode: id,
        isRoutine,
        description,
        defaultTestcode,
        specimenType,
        specimenTypeDescription,
        defaultLabSpecimenType: Number(defaultLabSpecimenType),
        isOnsite,
        tests: testResult
          ? (testResult.map((elem: any) => ({
              description: elem.drug,
              abbreviation: elem.testAbbreviation,
              code: elem.testCode,
              specimenTypeDescription: elem.matrix,
            })) as OnSiteTest['tests'])
          : [],
      },
    ] as OnSiteTest[];

    return {
      items,
    };
  } catch (error: any) {
    if ('code' in error) {
      throw normalizeError(error);
    } else {
      throw normalizeError(
        new GenericError(ErrorTypes.Unknown, error.message ?? ''),
      );
    }
  }
}

export async function getOnSiteTestsDevicesAsync(
  args: OnSiteTestsDevicesArg,
): Promise<OnSiteTestResult> {
  try {
    const { data } = await apiClient.get(`/Tests/${args.agencyCode}/Devices`);

    const { data: OnSiteTests } = data;

    const items = OnSiteTests.map(
      ({ itemId: id, description, matrix }: any) =>
        ({
          id,
          description,
          specimenType: matrix,
        } as OnSiteTest),
    ) as OnSiteTest[];

    return {
      items,
    };
  } catch (error: any) {
    if ('code' in error) {
      throw normalizeError(error);
    } else {
      throw normalizeError(
        new GenericError(ErrorTypes.Unknown, error.message ?? ''),
      );
    }
  }
}

export async function getOnSiteTestsDevicesResultsAsync(
  args: OnSiteTestsDevicesResultsArg,
): Promise<OnSiteTestResult> {
  try {
    const { data } = await apiClient.get(
      `/Tests/${args.agencyCode}/Devices/${args.deviceId}/TestResults`,
    );

    const { data: OnSiteTestsDevicesResults } = data;

    const items = OnSiteTestsDevicesResults.map(
      ({ drug, testCode, testAbbreviation, matrix }: any) =>
        ({
          id: testCode,
          code: testCode,
          drug: drug,
          description: drug,
          abbreviation: testAbbreviation,
          specimenTypeDescription: matrix,
        } as Test),
    ) as Test[];

    return {
      items,
    };
  } catch (error: any) {
    if ('code' in error) {
      throw normalizeError(error);
    } else {
      throw normalizeError(
        new GenericError(ErrorTypes.Unknown, error.message ?? ''),
      );
    }
  }
}
