// import { SerializedError } from "@reduxjs/toolkit";

export enum ErrorTypes {
  Unknown = 'UNKNOWN'
}

export enum CollectionErrorTypes {
  SelectedAgencyError = 'SelectedAgencyError'
}

export interface IError {
  id?: string;
  // type?: string;
  code?: string;
  message?: string;
  name?: string;
  stack?: string;
}

interface IResponse {
  response: any;
}

export class GenericError extends Error implements IError, IResponse  {
  readonly code: string;
  readonly response: any;
  constructor(code: string, message?: string, response?: any) {
    super(message);
    this.code = code;
    this.response = response;
  }
}

export class CollectionError extends GenericError {
};
