import { createSlice } from '@reduxjs/toolkit';
import { CancellationReason } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  createListAsyncThunk,
  GenericListSelectorResult,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';
import {
  getCancellationReasonsByIdAsync,
  CancellationReasonsByIdArgs,
} from 'services/cancellations-reasons-service';

// DEFINITION
const sliceConfiguration = {
  name: 'cancellationReasons',
};
const initialState = createInitialSlice<CancellationReason>();

// THUNKS
const fetchCancellationReasons = createListAsyncThunk<
  CancellationReason,
  CancellationReasonsByIdArgs
>(getCancellationReasonsByIdAsync, {
  ...sliceConfiguration,
  type: 'fetchCancellationReasons',
});

export { fetchCancellationReasons };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchCancellationReasons.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getCancellationReasons = (
  state: any,
): GenericListSelectorResult<CancellationReason> => {
  const { status, ids, meta, error } = getStateListMetas<CancellationReason>(
    state,
    'all',
    sliceConfiguration,
  );
  const entities = getStateListEntities<CancellationReason>(
    state,
    ids,
    sliceConfiguration,
  );
  return [entities, { ...meta, status }, error];
};
