import { apiClient } from 'clients';
import { User } from 'models';
import { handleFetchError } from 'utils/error-utils';

export interface UserByIdArgs {
  id: string;
}

export async function getUserByIdAsync(
  args: UserByIdArgs,
): Promise<User | null> {
  try {
    const { data } = await apiClient.get(`/usermanagement/users/${args.id}`);

    if (!!data) {
      const {
        userId,
        userName: username,
        firstName,
        lastName,
        agencyGroupId,
        agencyGroupName,
      } = data;

      return {
        id: username,
        userId,
        username,
        firstName,
        lastName,
        agency: {
          id: agencyGroupId as number,
          name: agencyGroupName,
        },
      };
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
