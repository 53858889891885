import { createSlice } from '@reduxjs/toolkit';
import { OrderTest } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  OrderTestsArg,
  getOrderTestsAsync,
  OtherTestsArg,
  getOtherTestsAsync,
} from 'services/tests-service';
import {
  createListAsyncThunk,
  GenericListSelectorResult,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';

// DEFINITION
const sliceConfiguration = {
  name: 'tests',
};
const initialState = createInitialSlice<OrderTest>();

// THUNKS
const fetchOrderTestsBy = createListAsyncThunk<OrderTest, OrderTestsArg>(
  getOrderTestsAsync,
  {
    ...sliceConfiguration,
    type: 'fetchOrderTestsBy',
    listNameFormatter: ({ donorId, agencyCode, isConfirmReditest }) =>
      `donors/${donorId}/agencies/${agencyCode}${
        isConfirmReditest ? `?isConfirmReditest=${isConfirmReditest}` : ''
      }`,
  },
);

const fetchOtherTestsBy = createListAsyncThunk<OrderTest, OtherTestsArg>(
  getOtherTestsAsync,
  {
    ...sliceConfiguration,
    type: 'fetchOtherTestsBy',
    listNameFormatter: ({ userAgencyId, agencyCode }) =>
      `agencyGroups/${userAgencyId}/agencies/${agencyCode}`,
  },
);

export { fetchOrderTestsBy, fetchOtherTestsBy };

// SLICE
const testSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchOrderTestsBy.addCasesTo?.(builder);
    fetchOtherTestsBy.addCasesTo?.(builder);
  },
});

// Action creators are generated for each case reducer function
// export const { } = donorsSlice.actions
export default testSlice.reducer;

// SELECTORS

export const getOrderTests =
  (
    donorId: number,
    agencyCode: number,
    specimenType?: string,
    isConfirmReditest?: boolean,
  ) =>
  (state: any): GenericListSelectorResult<OrderTest> => {
    const listName = `donors/${donorId}/agencies/${agencyCode}${
      isConfirmReditest ? `?isConfirmReditest=${isConfirmReditest}` : ''
    }`;
    const { status, ids, meta, error } = getStateListMetas<OrderTest>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<OrderTest>(
      state,
      ids,
      sliceConfiguration,
    );
    let entitiesBySpecimen = entities;

    if (!!specimenType) {
      entitiesBySpecimen = entitiesBySpecimen.filter(
        (e) => e.specimenTypeDescription === specimenType,
      );
    }

    return [entitiesBySpecimen, { ...meta, status }, error];
  };

export const getOtherTests =
  (userAgencyId: number, agencyCode: number, specimenType?: string) =>
  (state: any): GenericListSelectorResult<OrderTest> => {
    const listName = `agencyGroups/${userAgencyId}/agencies/${agencyCode}`;
    const { status, ids, meta, error } = getStateListMetas<OrderTest>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<OrderTest>(
      state,
      ids,
      sliceConfiguration,
    );

    let entitiesBySpecimen = entities;

    if (!!specimenType) {
      entitiesBySpecimen = entitiesBySpecimen.filter(
        (e) => e.specimenTypeDescription === specimenType,
      );
    }
    if (!!entitiesBySpecimen) {
      entitiesBySpecimen = entitiesBySpecimen.sort((a, b) =>
        (a.description || '').localeCompare(b.description || ''),
      );
    }

    return [entitiesBySpecimen, { ...meta, status }, error];
  };
