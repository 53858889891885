import { createSlice } from '@reduxjs/toolkit';
import {
  createInitialSlice,
  createItemAsyncThunk,
  GenericItemSelectorResult,
  getStateItemMetas,
} from 'store/factory';
import {
  getDonorScheduledCollectionsByAsync,
  ScheduledCollectionByDonorIdArgs,
} from 'services/scheduled-collections-service';
import { DonorScheduledCollections } from 'models';

// DEFINITION
const sliceConfiguration = {
  name: 'donorScheduledCollections',
};
const initialState = createInitialSlice<DonorScheduledCollections>();

// THUNKS
const fetchDonorScheduledCollectionsBy = createItemAsyncThunk<
  DonorScheduledCollections,
  ScheduledCollectionByDonorIdArgs
>(getDonorScheduledCollectionsByAsync, {
  ...sliceConfiguration,
  type: 'fetchDonorScheduledCollectionsBy',
});

export { fetchDonorScheduledCollectionsBy };

// SLICE
const donorScheduledCollectionsSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {
    resetDonorScheduledCollections: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    fetchDonorScheduledCollectionsBy.addCasesTo(builder);
  },
});

export const { resetDonorScheduledCollections } =
  donorScheduledCollectionsSlice.actions;

// Action creators are generated for each case reducer function
export default donorScheduledCollectionsSlice.reducer;

// SELECTORS
export const getDonorScheduledCollectionsById =
  (id: number) =>
  (state: any): GenericItemSelectorResult<DonorScheduledCollections> => {
    const {
      status,
      error,
      meta,
      data = {} as DonorScheduledCollections,
    } = getStateItemMetas<DonorScheduledCollections>(
      state,
      id,
      sliceConfiguration,
    );
    return [data, { ...meta, status }, error];
  };
