declare global {
  interface Window {
    env: any;
    RedirectToLegacyIfDesktop: Function;
    mwbScanner: any;
    mwbScannerConfigLoaded: boolean;
  }
}
type EnvType = {
  REACT_APP_TITLE: string;
  REACT_APP_IDLE_TIMEOUT: number;
  REACT_APP_USE_MOCK_API: string;
  REACT_APP_API_URL: string;
  REACT_APP_ITEMS_PER_PAGE: number;
  REACT_APP_TA_DESKTOP_URL: string;
  REACT_APP_SITE_UNDER_MAINTENANCE: string;
  REACT_APP_VERSION_NUMBER: string;
  REACT_APP_GOOGLE_TAG_MANAGER: string;
  REACT_APP_AVOID_DEVICE_REDIRECTION: string;
  REACT_APP_REDIRECT_DOMAIN: string;
  REACT_APP_REDIRECT_DOMAIN_TO: string;
};
export const env: EnvType = { ...process.env, ...window.env };
