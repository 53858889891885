import { apiClient } from 'clients';
import { Donor } from 'models';
import { handleFetchError } from 'utils/error-utils';
import {
  FetchByIdArgs,
  FetchListArgs,
  GenericBase64FileResult,
  GenericListResult,
  PaginationArgs,
} from './types';

export interface DonorsListResult extends GenericListResult<Donor> {}

export interface DonorByIdArgs extends FetchByIdArgs<number> {
  includeResponsibleParties?: boolean;
  includeNotes?: boolean;
}
export interface DonorListArgs extends FetchListArgs, PaginationArgs {}

export function formatDonorFullName(donor: Donor, includeUniqueId = false) {
  return `${donor.firstName}${
    !!donor.middleName?.length ? ` ${donor.middleName}` : ''
  } ${donor.lastName}${
    !!donor.uniqueId && includeUniqueId ? ` (${donor.uniqueId})` : ''
  }`;
}

interface DonorPictureArgs {
  donorId: number;
}

interface DonorPictureCreateArgs extends DonorPictureArgs {
  pictureBase64: string;
}

// interface DonorPostArgs {
//   donorGroupId: number;
//   firstName: string;
//   mi: string;
//   lastName: string;
//   uniqueId: string;
//   dateOfBirth: Date;
//   intakeDate: Date;
//   sex: 'M' | 'F';
//   agencyCode: number;
//   isActive: boolean;
//   phoneNumber: string;
//   collectionSiteId: number;
//   donorAddress: {
//     addressLine1: string;
//     addressLine2: string;
//     city: string;
//     state: string;
//     zip: string;
//   };
// }
interface DonorPostArgs {
  donorGroupId: any;
  firstName: any;
  mi: any;
  lastName: any;
  uniqueId: any;
  dateOfBirth: any;
  intakeDate: any;
  sex: any;
  agencyCode: number;
  isActive: boolean;
  phoneNumber: any;
  collectionSiteId: any;
  donorAddress: {
    addressLine1: any;
    addressLine2: any;
    city: any;
    state: any;
    zip: any;
  };
}

export async function getDonorsAsync(
  args: DonorListArgs,
): Promise<DonorsListResult> {
  try {
    const { data } = await apiClient.get('/donors', {
      params: {
        searchFilter: args.filter?.toLowerCase(),
        page: args.page || 1,
        rowsPerPage: args.pageSize || 25,
      },
    });

    const {
      donorList,
      page,
      rowsPerPage: pageSize,
      totalOfRows: totalCount,
    } = data;

    const items = donorList.map(
      ({
        donorId: id,
        firstName,
        middleName,
        lastName,
        uniqueId,
        agencyName,
        agencyCode,
      }: any) => ({
        id,
        firstName,
        middleName,
        lastName,
        uniqueId,
        agency: {
          id: agencyCode,
          name: agencyName,
        },
      }),
    );

    return {
      items,
      meta: {
        page,
        pageSize,
        totalCount,
      },
    };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getDonorByIdAsync(
  args: DonorByIdArgs,
): Promise<Donor | null> {
  try {
    const [
      { data: donor },
      { data: responsiblePartiesData },
      { data: donorNotesData },
    ] = await Promise.allSettled([
      apiClient.get(`/donors/${args.id}`),
      args.includeResponsibleParties
        ? apiClient
            .get(`/donors/${args.id}/responsibleparties`)
            .then(({ data }) => ({ data: data?.responsiblePartyList }))
        : Promise.resolve({ data: undefined }),
      args.includeNotes
        ? apiClient
            .get(`/donors/${args.id}/notes`)
            .then(({ data }) => ({ data: data?.donorNoteList }))
        : Promise.resolve({ data: undefined }),
    ]).then((r: any) =>
      r.map((r: any) => (r.status === 'fulfilled' ? r.value : {})),
    );

    if (donor) {
      const {
        donorId,
        firstName,
        middleName,
        lastName,
        uniqueId,
        agencyCode,
        agencyName,
        donorGroupId,
        donorGroupName,
        isActive,
        isRandomTestSchedulingEnabled,
        phoneNumber,
        callInCode,
        sex: gender,
        dateOfBirth,
        intakeDate,
        addressLine1,
        addressLine2,
        city,
        state,
        zip: zipCode,
        collectionSiteId,
        collectionSiteName,
      } = donor;

      const responsibleParties = responsiblePartiesData?.map(
        ({
          responsiblePartyId: id,
          uniqueId,
          userResponsiblePartyId: userId,
          donorResponsiblePartyId: donorId,
          name,
          fullName,
        }: any) => ({
          id,
          uniqueId,
          userId,
          donorId,
          name,
          fullName,
        }),
      );

      const donorNotes = donorNotesData?.map(
        ({
          donorNoteId: id,
          note: text,
          createdStamp,
          modifiedStamp,
        }: any) => ({
          id,
          text,
          date: modifiedStamp ? modifiedStamp : createdStamp,
        }),
      );

      const item: Donor = {
        id: donorId,
        firstName,
        middleName,
        lastName,
        uniqueId,
        agency: {
          id: agencyCode,
          code: Number(agencyCode),
          name: agencyName,
        },
        isActive,
        isRandomTestSchedulingEnabled,
        phoneNumber,
        callInCode,
        gender,
        dateOfBirth,
        intakeDate,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city: city,
          state: state,
          zipCode: zipCode,
        },
        ...(!!collectionSiteId && {
          collectionSite: {
            id: collectionSiteId,
            name: collectionSiteName,
          },
        }),
        ...(!!donorGroupId && {
          donorGroup: {
            id: donorGroupId,
            name: donorGroupName,
          },
        }),
        responsibleParties,
        donorNotes,
      };

      return item;
    }
    return null;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getLastCollectionSite(donorId: string | undefined) {
  try {
    const {
      data: {
        data: { lastCollectionSite },
      },
    } = await apiClient.get(`Donors/${donorId}/LastCollectionSite`);

    return lastCollectionSite;
  } catch (error: any) {}
}

export async function getDonorProfilePictureAsync({
  donorId,
}: DonorPictureArgs): Promise<GenericBase64FileResult | null> {
  try {
    const {
      data: { data: content },
    } = await apiClient.get(`/donors/${donorId}/photo`);

    if (!!content) {
      return {
        content,
        mimeType: 'image/png',
      };
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function postDonorProfilePictureAsync({
  donorId,
  pictureBase64,
}: DonorPictureCreateArgs): Promise<boolean> {
  try {
    await apiClient.post(`/donors/${donorId}/photo`, {
      imageBase64: pictureBase64,
    });
    return true;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function putDonorProfilePictureAsync({
  donorId,
  pictureBase64,
}: DonorPictureCreateArgs): Promise<boolean> {
  try {
    await apiClient.put(`/donors/${donorId}/photo`, {
      imageBase64: pictureBase64,
    });
    return true;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function deleteDonorProfilePictureAsync({
  donorId,
}: DonorPictureArgs): Promise<boolean> {
  try {
    await apiClient.delete(`/donors/${donorId}/photo`);
    return true;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function putDonor(props: DonorPostArgs, donorId: string) {
  try {
    await apiClient.put(`/donors/${donorId}`, props);
    return true;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function postDonor(props: DonorPostArgs) {
  try {
    const {
      data: {
        data: { donorId },
      },
    } = await apiClient.post(`/donors`, props);
    return donorId;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function postRandomScheduledCollections(
  donorId: string | undefined,
) {
  try {
    const { data } = await apiClient.post(
      `/donors/${donorId}/RandomScheduledCollections`,
    );
    return data;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
