import { ErrorTypes, GenericError } from "models";

export function normalizeError(error: any) {
  const {
    id,
    name,
    message,
    stack,
    code,
  } = error;
  return {
    id,
    name,
    message,
    stack,
    code,
  }
}

export function handleFetchError(error: any) {
  let rejectedError; 
  if ('code' in error) {
    rejectedError = normalizeError(error);
  } else {
    rejectedError = normalizeError(new GenericError(ErrorTypes.Unknown, error.message ?? ''));
  }
  return rejectedError;
}