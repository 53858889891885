import { createSlice } from '@reduxjs/toolkit'
import { CollectionType } from 'models';
import { createInitialSlice } from 'store/utils';
import { createListAsyncThunk, GenericListSelectorResult, getStateListEntities, getStateListMetas } from 'store/factory';
import { getCollectionTypesAsync, ListByAgencyGroupArgs } from 'services/agency-groups';

// DEFINITION
const sliceConfiguration = {
  name: 'collectionTypes',
};
const initialState = createInitialSlice<CollectionType>();

// THUNKS
const fetchCollectionTypes = createListAsyncThunk<CollectionType, ListByAgencyGroupArgs>(getCollectionTypesAsync, { ...sliceConfiguration, type: 'fetchCollectionTypes' });

export { fetchCollectionTypes };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchCollectionTypes.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getCollectionTypes = (state: any): GenericListSelectorResult<CollectionType> => {
  const { status, ids, meta, error } = getStateListMetas<CollectionType>(state, 'all', sliceConfiguration);
  const entities = getStateListEntities<CollectionType>(state, ids, sliceConfiguration);
  return [entities, { ...meta, status }, error];
};