import { apiClient } from 'clients';
import { Collector } from 'models';
import { handleFetchError } from 'utils/error-utils';
import { FetchListArgs, GenericListResult } from './types';

export interface CollectorsByIdArgs extends FetchListArgs {
  agencyGroupId: string;
}

export async function getCollectorsByIdAsync({
  agencyGroupId,
}: CollectorsByIdArgs): Promise<GenericListResult<Collector>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/Collectors/`,
    );

    const items =
      data?.data?.map(
        (collector: {
          collectorId: number;
          userName: string;
          lastName: string;
        }) =>
          ({
            id: collector.collectorId,
            name: collector.userName,
          } as Collector),
      ) || [];

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
