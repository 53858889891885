import { apiClient } from 'clients';
import { env } from 'env';
import { DateTime } from 'luxon';
import {
  DonorScheduledCollections,
  OrderTest,
  ScheduledCollection,
  ScheduleTestLab,
  ScheduleTestOnsite,
} from 'models';
import { handleFetchError } from 'utils/error-utils';
import { generateUUID } from 'utils/uuid-utils';
import {
  FetchListArgs,
  GenericListResult,
  PaginationArgs,
  PaginationMetaData,
  FetchByIdArgs,
} from './types';

export interface ScheduledCollectionMetaData extends PaginationMetaData {
  systemDate?: string;
}

export interface ScheduledCollectioResult
  extends GenericListResult<ScheduledCollection, ScheduledCollectionMetaData> {}

export interface ScheduledCollectioListArgs
  extends FetchListArgs,
    PaginationArgs {
  donorId?: number;
  forceLoad?: boolean;
}

export interface ScheduledCollectionByDonorIdArgs
  extends FetchByIdArgs<number> {}

export interface ScheduledCollectioListArgs
  extends FetchListArgs,
    PaginationArgs {
  donorId?: number;
  forceLoad?: boolean;
}

export async function getScheduledCollectionsAsync(
  args: ScheduledCollectioListArgs,
): Promise<ScheduledCollectioResult> {
  try {
    const { data } = await apiClient.get(
      '/collections/donors/scheduledCollections',
      {
        params: {
          searchFilter: args.filter?.toLowerCase(),
          page: args.page || 1,
          rowsPerPage: args.pageSize || env.REACT_APP_ITEMS_PER_PAGE,
        },
      },
    );

    const {
      scheduleCollectionViewModel,
      page,
      systemDate,
      rowsPerPage: pageSize,
      totalOfRows: totalCount,
    } = data;

    const items = scheduleCollectionViewModel.map(
      ({
        donorId,
        firstName,
        middleName,
        lastName,
        uniqueId,
        agencyName,
        agencyCode,
        collectionSiteId,
        donorGroupId,
        donorGroupName,
        collectedDate,
        isOnsite,
        scheduledTestType,
      }: any) => ({
        id: generateUUID(),
        donor: {
          id: donorId,
          firstName,
          middleName,
          lastName,
          uniqueId,
          agency: {
            id: agencyCode,
            name: agencyName,
          },
        },
        date: collectedDate,
        siteId: collectionSiteId,
        donorGroup: {
          id: donorGroupId,
          name: donorGroupName,
        },
        isOnSite: !!isOnsite,
        scheduledTestType,
      }),
    );

    return {
      items,
      meta: {
        page,
        pageSize,
        totalCount,
        systemDate,
      },
    };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getDonorScheduledCollectionsByAsync(
  args: ScheduledCollectionByDonorIdArgs,
): Promise<DonorScheduledCollections | null> {
  const donorId = args.id;
  try {
    const {
      data: { data },
    } = await apiClient.get(
      `/collections/Donors/${donorId}/scheduledCollections`,
    );

    const {
      laboratoryScheduledCollectionViewModel,
      onsiteScheduledCollectionViewModel,
      firstName,
      middleName,
      lastName,
    } = data;

    // Order Onsite Tests by timestamp
    const onsiteScheduledCollectionViewModelOrdered =
      onsiteScheduledCollectionViewModel.length > 1
        ? onsiteScheduledCollectionViewModel.sort(
            (a: any, b: any) =>
              DateTime.fromISO(a.createdStamp).toUnixInteger() -
              DateTime.fromISO(b.createdStamp).toUnixInteger(),
          )
        : onsiteScheduledCollectionViewModel;

    // Remove the repeated device tests ->
    const uniqueDescriptions = new Set<string>();

    const onsiteScheduledCollectionViewModelOrderedUnique =
      onsiteScheduledCollectionViewModelOrdered.filter((item: any) => {
        const shortDescription = item.description.substring(0, 10);
        if (!uniqueDescriptions.has(shortDescription)) {
          uniqueDescriptions.add(shortDescription);
          return true;
        }
        return false;
      });

    const normalizedData: DonorScheduledCollections = {
      id: donorId,
      hasLabScheduledCollections:
        laboratoryScheduledCollectionViewModel?.length > 0,
      hasOnSiteScheduledCollections:
        onsiteScheduledCollectionViewModel?.length > 0,
      hasScheduledCollections:
        laboratoryScheduledCollectionViewModel?.length > 0 ||
        onsiteScheduledCollectionViewModel?.length > 0,
      scheduleTestsLab:
        laboratoryScheduledCollectionViewModel?.map(
          (labTest: any) =>
            ({
              scheduleId: labTest?.scheduledId,
              collectionType: labTest?.collectionType,
              source: labTest?.source,
              orderTest:
                labTest?.orderTestViewModel?.map(
                  (ot: any) =>
                    ({
                      id: ot?.testCode,
                      agencyCode: ot?.agencyCode,
                      defaultTestCode: ot?.defaultTestCode,
                      description: ot?.description,
                      isRoutine: ot?.isRoutine,
                      onLabel: ot?.onLabel,
                      specimenType: ot?.specimenType,
                      specimenTypeDescription: ot?.specimenTypeDescription,
                      testCode: ot?.testCode,
                    } as OrderTest),
                ) || [],
            } as ScheduleTestLab),
        ) || [],
      scheduleTestsOnsite:
        onsiteScheduledCollectionViewModelOrderedUnique?.map(
          (onsiteTest: any) =>
            ({
              scheduleId: onsiteTest?.scheduledId,
              deviceId: onsiteTest?.deviceId,
              description: onsiteTest?.description,
              collectionType: onsiteTest?.collectionType,
              source: onsiteTest?.source,
              specimenType: onsiteTest?.specimenType,
              created_at: onsiteTest?.createdStamp,
            } as ScheduleTestOnsite),
        ) || [],
      donor: {
        donorId,
        firstName,
        middleName,
        lastName,
      },
    };

    if (!!data) {
      return normalizedData;
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function deleteScheduleCollectionsAsync({
  donorId,
  reasonId,
}: {
  donorId: number;
  reasonId: number;
}): Promise<boolean> {
  try {
    await apiClient.delete(
      `/Collections/Donors/${donorId}/ScheduledCollections`,
      { data: { cancellationTypeId: reasonId } },
    );
    return true;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
