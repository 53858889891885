import { Agency, CollectionSite } from 'models';

export type NamedEntity = {
  id?: string;
  name?: string;
};

export interface Address {
  line1: string;
  line2?: string;
  city?: string;
  state: string;
  zipCode: string;
}

export interface ResponsibleParty {
  id?: number;
  uniqueId?: string;
  userId?: number;
  donorId?: number;
  name?: string;
  fullName?: string;
}
export interface DonorNote {
  id?: number;
  text?: string;
  date?: string;
}
// export type CollectionSite = NamedEntity;

export type DonorGroup = NamedEntity;

export enum Genders {
  Male = 'M',
  Female = 'F',
}

export interface Donor {
  id: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  uniqueId?: string;
  isActive?: boolean;
  isRandomTestSchedulingEnabled?: boolean;
  phoneNumber?: string;
  callInCode?: string;
  gender?: Genders;
  dateOfBirth?: Date;
  agency?: Agency;
  address?: Address;
  collectionSite?: CollectionSite;
  donorGroup?: DonorGroup;
  responsibleParties?: ResponsibleParty[];
  donorNotes?: DonorNote[];
  intakeDate?: Date;
}
