import { createSlice } from '@reduxjs/toolkit';
import { OnSiteTest, Test } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  getOnSiteScheduledTestsDevicesAsync,
  getOnSiteTestsDevicesAsync,
  getOnSiteTestsDevicesResultsAsync,
  OnSiteScheduledTestsArg,
  OnSiteTestsDevicesArg,
  OnSiteTestsDevicesResultsArg,
} from 'services/tests-service';
import {
  createListAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';

// DEFINITION
const sliceConfiguration = {
  name: 'testsOnSite',
};
const initialState = createInitialSlice<OnSiteTest>();

// THUNKS
const fetchOnSiteTestsDevicesBy = createListAsyncThunk<
  OnSiteTest,
  OnSiteTestsDevicesArg
>(getOnSiteTestsDevicesAsync, {
  ...sliceConfiguration,
  type: 'fetchOnSiteTestsDevicesBy',
  listNameFormatter: ({ agencyCode }) => `tests/${agencyCode}/devices`,
});

const fetchOnSiteTestsDevicesResultsBy = createListAsyncThunk<
  OnSiteTest,
  OnSiteTestsDevicesResultsArg
>(getOnSiteTestsDevicesResultsAsync, {
  ...sliceConfiguration,
  type: 'fetchOnSiteTestsDevicesResultsBy',
  listNameFormatter: ({ agencyCode, deviceId }) =>
    `tests/${agencyCode}/devices/${deviceId}/testresults`,
});

export { fetchOnSiteTestsDevicesBy, fetchOnSiteTestsDevicesResultsBy };

// SLICE
const testSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchOnSiteTestsDevicesBy.addCasesTo?.(builder);
    fetchOnSiteTestsDevicesResultsBy.addCasesTo?.(builder);
  },
});

export default testSlice.reducer;

// SELECTORS
export const getOnSiteTestsDevices =
  (agencyCode: number) =>
  (state: any): GenericListSelectorResult<OnSiteTest> => {
    const listName = `tests/${agencyCode}/devices`;
    const { status, ids, meta, error } = getStateListMetas<OnSiteTest>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<OnSiteTest>(
      state,
      ids,
      sliceConfiguration,
    );

    return [entities, { ...meta, status }, error];
  };

export const getOnSiteTestsDevicesResults =
  (agencyCode: number, deviceId: number) =>
  (state: any): GenericListSelectorResult<Test> => {
    const listName = `tests/${agencyCode}/devices/${deviceId}/testresults`;
    const { status, ids, meta, error } = getStateListMetas<Test>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<Test>(state, ids, sliceConfiguration);

    return [entities, { ...meta, status }, error];
  };
