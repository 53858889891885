import { apiClient } from 'clients';
import { env } from 'env';
import { Result, SpecimenTypes, TestProcedure, TestResults } from 'models';
import { handleFetchError } from 'utils/error-utils';
import {
  FetchByIdArgs,
  FetchListArgs,
  GenericBase64FileResult,
  GenericListResult,
  PaginationArgs,
} from './types';

// export type ResultsThunkPayload = {
//   items: Result[];
//   meta?: PaginationMetaData;
// }

// export type ResultThunkPayload = Result | null;

// export type ResultsThunkArg = FetchListArgs & PaginationArgs & { donorId?: number, forceLoad?: boolean; };

export interface ResultsListResult extends GenericListResult<Result> {}
export interface ResultByIdArgs extends FetchByIdArgs<string> {}
export interface ResultsListArgs extends FetchListArgs, PaginationArgs {
  donorId?: number;
}
export interface FinalReportArgs {
  specimenId: number;
  specimenVersion?: number;
  isOnsite?: boolean;
}
export interface CogsReportArgs {
  requisitionNumber: string;
}

export function normalizedResults(items: any[]): Result[] {
  return items.map(
    ({
      donorId,
      firstName,
      middleName,
      lastName,
      uniqueId,
      agencyName,
      agencyCode,

      specimenId,
      specimenType,
      requisitionNumber,
      accession,
      accessionNum,
      accessionDate,
      identification,
      collectedDate,
      reportedDate,
      collector,

      observed,
      printed,
      hasCoC,
      hasSignature,
      isOnsite,

      testLogicVersion,
      testOverallResult,

      testResultList,
    }: any) =>
      ({
        id: specimenId,
        specimenType: Object.values(SpecimenTypes).includes(
          specimenType?.toLowerCase(),
        )
          ? specimenType?.toLowerCase()
          : SpecimenTypes.Unknown,
        requisitionNumber,
        identification,

        accession,
        accessionNum,
        accessionDate,

        collector,
        collectedDate,

        reportedDate,

        observed: !!observed,
        printed: !!printed,
        hasCoC: !!hasCoC,
        hasSignature: !!hasSignature,
        isOnsite: !!isOnsite,

        testLogicVersion,
        testOverallResult: Object.values(TestResults).includes(
          testOverallResult?.toLowerCase(),
        )
          ? testOverallResult?.toLowerCase()
          : TestResults.Unknown,

        donor: {
          id: donorId,
          firstName,
          middleName,
          lastName,
          uniqueId,
          agency: {
            id: agencyCode,
            name: agencyName,
            code: agencyCode,
          },
        },

        tests: testResultList.map(
          ({
            test,
            testAbbreviation,
            testCode,
            drug,
            drugAbbreviation,
            testResult,
            testProcedure,
          }: any) => ({
            description: test,
            abbreviation: testAbbreviation,
            code: testCode,
            drug: {
              name: drug,
              abbreviation: drugAbbreviation,
            },
            result: Object.values(TestResults).includes(
              testResult?.toLowerCase(),
            )
              ? testResult?.toLowerCase()
              : TestResults.Unknown,
            procedure: Object.values(TestProcedure).includes(
              testProcedure?.toLowerCase(),
            )
              ? testProcedure?.toLowerCase()
              : TestProcedure.Unknown,
          }),
        ),
      } as Result),
  );
}

export async function getResultsAsync(
  args: ResultsListArgs,
): Promise<ResultsListResult> {
  try {
    const { data } = await apiClient.get('/results', {
      params: {
        searchFilter: args.filter?.toLowerCase(),
        page: args.page || 1,
        rowsPerPage: args.pageSize || env.REACT_APP_ITEMS_PER_PAGE,
        ...(!!args.donorId && {
          donorId: args.donorId,
        }),
      },
    });
    const {
      resultList,
      page,
      rowsPerPage: pageSize,
      totalOfRows: totalCount,
    } = data;

    const items = normalizedResults(resultList);

    return {
      items,
      meta: {
        page,
        pageSize,
        totalCount,
      },
    };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getResultByIdAsync(arg: ResultByIdArgs) {
  try {
    const {
      data: { resultList: result },
    } = await apiClient.get('/results', {
      params: { specimenId: arg?.id, page: 1, rowsPerPage: 25 },
    });

    if (!!result && result.length > 0) {
      const [item] = normalizedResults(result);

      return item;
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getResultFinalReportAsync({
  specimenId,
  specimenVersion = 2,
  isOnsite = false,
}: FinalReportArgs): Promise<GenericBase64FileResult | null> {
  try {
    const {
      data: { data: content },
    } = await apiClient.get('/resources/finalreports', {
      params: { specimenId, specimenVersion, isOnsite },
    });

    if (!!content) {
      return {
        content,
        mimeType: 'application/pdf',
      };
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getCocsReportAsync({
  requisitionNumber,
}: CogsReportArgs): Promise<GenericBase64FileResult | null> {
  try {
    const {
      data: { data: content },
    } = await apiClient.get(`/resources/cocs/${requisitionNumber}`);

    if (!!content) {
      return {
        content,
        mimeType: 'application/pdf',
      };
    } else {
      return null;
    }
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

type SignatureImageResponse = {
  content: {
    collectorSignature: string;
    donorSignature: string;
  };
  mimeType: 'image/png';
};

export async function getSignatureImageAsync({
  requisitionNumber,
}: CogsReportArgs): Promise<SignatureImageResponse | null> {
  try {
    const {
      data: { data: content },
    } = await apiClient.get(`/resources/signatures/${requisitionNumber}`);
    return {
      content,
      mimeType: 'image/png',
    };
  } catch (error: any) {
    return null;
  }
}
