import { IError } from "models";

const SLICE_NAME = 'errors';

export const getAllErrors = (state: any): IError[] => {
  const slice: IError[] = state[SLICE_NAME];
  return slice;
};

export const getNextError = (allowed?: string | string[]) => (state: any): IError | undefined => {
  let errors = getAllErrors(state);
  if (!!allowed && allowed.length > 0) {
    if (!Array.isArray(allowed)) {
      allowed = [allowed];
    }
    errors = errors.filter(error => !!error.code && allowed?.includes(error.code));
  }
  const [firstError] = errors;
  return firstError;
};

export const getErrorById = (id: string) => (state: any): IError | undefined => {
  const errors = getAllErrors(state);
  const matchError = errors.find(error => error.id === id);
  return matchError;
};