import {
  ActionReducerMapBuilder,
  AsyncThunk,
  AsyncThunkOptions,
  createAsyncThunk,
  CreateSliceOptions,
  Dispatch,
  Draft,
  SerializedError,
} from '@reduxjs/toolkit';
import { addError } from './errors';
import {
  EntityState,
  FetchListArgs,
  MetaData,
  PaginationArgs,
  StateSlice,
  StoreStatus,
} from './types';
import { FetchByIdArgs, GenericListResult, WithId } from 'services/types';
import { addItemsToState, addItemToState, addListToState } from './utils';
import { normalizeError } from 'utils/error-utils';

/// TYPES

declare type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export type ThunkFetchResult<T> = T | GenericListResult<T> | null;

export interface ThunkFetch<T extends WithId> {
  (args: any): Promise<Partial<ThunkFetchResult<T>>>;
}

export interface ThunkDefinition<T extends WithId> {
  name?: string;
  // hasNextAction?: boolean;
  fetch: ThunkFetch<T>;
}

export interface CreateSliceWithThunksOptions<T extends WithId>
  extends Omit<CreateSliceOptions, 'initialState'>,
    Partial<Pick<CreateSliceOptions, 'initialState'>> {
  thunks: {
    [name: string]: ThunkDefinition<T> | ThunkFetch<T>;
  };
}

export interface SliceConfiguration {
  name: string;
}

export interface ThunkOptions<A> {
  type: string;
  listNameFormatter?: (args: A) => string;
}

type ItemThunk<T extends WithId | null, A, E extends AsyncThunkConfig = {}> =
  AsyncThunk<T, A, E> & {
    addCasesTo: (
      builder: ActionReducerMapBuilder<StateSlice<Exclude<T, null>>>,
    ) => void;
  };

type ListThunk<T, A, E extends AsyncThunkConfig = {}> = AsyncThunk<T, A, E> & {
  addCasesTo: (
    builder: ActionReducerMapBuilder<
      StateSlice<
        T extends GenericListResult<infer M>
          ? M extends WithId
            ? M
            : never
          : never
      >
    >,
  ) => void;
  next: AsyncThunk<T, A, E>;
};

type CaseHandler<T extends WithId, A> = (
  state: Draft<StateSlice<T>>,
  arg: A,
  payload?: GenericListResult<T>,
  error?: SerializedError,
  incremental?: boolean,
) => void;

export type GenericListSelectorResult<T, M = any> = [
  T[],
  MetaData & M,
  SerializedError?,
];
export type GenericItemSelectorResult<T, M = any> = [
  T | null,
  MetaData & M,
  SerializedError?,
];

/// BUILDERS

export function createInitialSlice<
  T extends WithId,
  M extends MetaData = MetaData,
>(status: StoreStatus = StoreStatus.Loading): StateSlice<T, M> {
  return {
    drafts: [],
    byId: {} as any,
    byList: {
      all: {
        status,
        ids: [],
      },
    },
  };
}

export function getListNameFor(
  { list, filter, ...args }: Partial<FetchListArgs>,
  listNameFormatter?: (arg: any) => string,
) {
  let name = list ?? 'all';
  if (listNameFormatter) {
    name = listNameFormatter(args);
  }
  if (!!filter?.length) {
    name = `${name}?filter=${filter.toLocaleLowerCase()}`;
  }
  return name;
}

function getActionType(type: string, sliceName?: string) {
  if (!!sliceName?.length) {
    return `${sliceName}/${type}`;
  }
  return type;
}

export function createItemAsyncThunk<
  T extends WithId,
  A extends FetchByIdArgs<T['id']>,
>(
  fetch: (args: A) => Promise<T | null>,
  {
    type,
    name: sliceName,
    ...options
  }: ThunkOptions<A> & SliceConfiguration & AsyncThunkOptions,
) {
  type = getActionType(type, sliceName);

  const thunk = createAsyncThunk<T | null, A>(type, async (args, api) => {
    try {
      return await fetch(args);
    } catch (error: any) {
      api.dispatch(addError(normalizeError(error)));
      return api.rejectWithValue(error);
    }
  }) as ItemThunk<T | null, A>;

  thunk.addCasesTo = (builder) => {
    builder
      .addCase(thunk.fulfilled, (state, { meta: { arg }, payload }) => {
        addItemToState(state, {
          data: { ...payload, id: arg.id } as T,
          status: StoreStatus.Idle,
        });
      })
      .addCase(thunk.pending, (state, { meta: { arg } }) => {
        const payload: EntityState<T> = {
          status: StoreStatus.Loading,
          data: { id: arg.id } as T,
        };
        addItemToState(state, payload);
      })
      .addCase(thunk.rejected, (state, { meta: { arg }, payload }) => {
        addItemToState(state, {
          status: StoreStatus.Idle,
          data: { id: arg.id } as T,
          error: normalizeError(payload) as SerializedError,
        });
      });
  };

  return thunk;
}

export function createListAsyncThunk<
  T extends WithId,
  A extends FetchListArgs & PaginationArgs,
>(
  fetch: (args: A) => Promise<GenericListResult<T>>,
  {
    type,
    name: sliceName,
    listNameFormatter,
    ...options
  }: ThunkOptions<A> & SliceConfiguration & AsyncThunkOptions,
) {
  type = getActionType(type, sliceName);

  const getThunkArgs = (args: A, api: any) => {
    const { [sliceName]: stateSlice }: any = api.getState();
    const listName = getListNameFor(args, listNameFormatter);
    const {
      byList: { [listName]: { ids = [], meta = {} } = {} },
    } = stateSlice;
    const { page = 0, pageSize, totalCount } = meta || {};

    return {
      stateSlice,
      listName,
      ids,
      meta,
      page,
      pageSize,
      totalCount,
    };
  };

  const thunk = createAsyncThunk<GenericListResult<T>, A>(
    type,
    async (args, api) => {
      try {
        const { ids, page, stateSlice } = getThunkArgs(args, api);

        if (args.forceLoad || page < (args.page ?? 1)) {
          return await fetch({ ...args, page: 1 });
        } else {
          return {
            items: ids.map((id: any) => ({ id, ...stateSlice.byId[id].data })),
          };
        }
      } catch (error: any) {
        api.dispatch(addError(normalizeError(error)));
        return api.rejectWithValue(error);
      }
    },
  ) as ListThunk<GenericListResult<T>, A>;

  thunk.next = createAsyncThunk<GenericListResult<T>, A>(
    `${type}/next`,
    async (args, api) => {
      try {
        const { meta, page, pageSize, totalCount } = getThunkArgs(args, api);

        if (!!page && !!pageSize && (totalCount ?? 0) > page * pageSize) {
          return await fetch({ ...args, page: page + 1, pageSize });
        } else {
          return {
            items: [],
            meta,
          };
        }
      } catch (error: any) {
        api.dispatch(addError(normalizeError(error)));
        return api.rejectWithValue(error);
      }
    },
  );

  thunk.addCasesTo = (builder) => {
    const handleListFullfilled: CaseHandler<T, A> = (
      state,
      arg,
      payload,
      _,
      incremental,
    ) => {
      const list = getListNameFor(arg, listNameFormatter);
      const items = payload?.items || [];
      const meta = payload?.meta || {};

      addItemsToState(state, items, StoreStatus.Idle);
      const ids = payload?.items?.map?.((item) => item.id ?? 0);
      addListToState(
        state,
        list,
        {
          ids,
          meta,
          status: StoreStatus.Idle,
        },
        incremental,
      );
    };

    const handleListPending: CaseHandler<T, A> = (state, arg) => {
      const payload = {
        status: StoreStatus.Loading,
      };
      const list = getListNameFor(arg, listNameFormatter);
      addListToState(state, list, payload);
    };

    const handleListRejected: CaseHandler<T, A> = (state, arg, _, error) => {
      const payload = {
        status: StoreStatus.Idle,
        error: normalizeError(error) as SerializedError,
      };
      const list = getListNameFor(arg, listNameFormatter);
      addListToState(state, list, payload);
    };

    builder
      .addCase(thunk.fulfilled, (state, { meta: { arg }, payload }) =>
        handleListFullfilled(state, arg, payload, undefined, false),
      )
      .addCase(thunk.pending, (state, { meta: { arg } }) =>
        handleListPending(state, arg),
      )
      .addCase(thunk.rejected, (state, { meta: { arg }, payload }) =>
        handleListRejected(state, arg, undefined, payload as SerializedError),
      )

      .addCase(thunk.next.fulfilled, (state, { meta: { arg }, payload }) =>
        handleListFullfilled(state, arg, payload, undefined, true),
      )
      .addCase(thunk.next.pending, (state, { meta: { arg } }) =>
        handleListPending(state, arg),
      )
      .addCase(thunk.next.rejected, (state, { meta: { arg }, payload }) =>
        handleListRejected(state, arg, undefined, payload as SerializedError),
      );
  };

  return thunk;
}

/// HELPERS

export function getDraftItem<T extends WithId>(state: StateSlice<T>) {
  if (!state.drafts) {
    state.drafts = [];
  }
  const prevEntityState: EntityState<Partial<T>> | undefined = state.drafts[0];
  return prevEntityState;
}

export function patchDraftItem<T extends WithId>(
  state: StateSlice<T>,
  payload: Partial<T>,
): StateSlice<T> {
  if (payload) {
    let prevEntityState = getDraftItem<T>(state);
    if (prevEntityState) {
      prevEntityState.data = { ...prevEntityState.data, ...payload };
    } else {
      putDraftItem(state, payload);
    }
  }
  return state;
}

export function putDraftItem<T extends WithId>(
  state: StateSlice<T>,
  payload: Partial<T> | null,
): StateSlice<T> {
  getDraftItem<T>(state);
  if (payload) {
    state.drafts[0] = { status: StoreStatus.Draft, data: payload };
  } else {
    state.drafts[0] = { status: StoreStatus.Draft, data: {} };
  }
  return state;
}

/// SELECTOR HELPERS

export function getStateItemMetas<T extends WithId>(
  state: any,
  id: T['id'],
  sliceConfiguration: SliceConfiguration,
) {
  const slice: StateSlice<T> = state[sliceConfiguration.name];
  const {
    status = StoreStatus.Loading,
    error,
    meta,
    data,
  } = slice.byId[id] || {};

  let normalizedStatus = status;
  if (normalizedStatus === StoreStatus.Idle && Object.keys(data).length === 0) {
    normalizedStatus = StoreStatus.NotFound;
  }

  return { status: normalizedStatus, data, meta, error };
}

export function getStateListMetas<T extends WithId>(
  state: any,
  listName: string,
  sliceConfiguration: SliceConfiguration,
) {
  const slice: StateSlice<T> = state[sliceConfiguration.name];
  const {
    status = StoreStatus.Loading,
    ids,
    meta = {},
    error,
  } = slice.byList[listName] || {};
  return { status, ids, meta, error };
}

export function getStateListEntities<T extends WithId>(
  state: any,
  ids: T['id'][],
  sliceConfiguration: SliceConfiguration,
) {
  const slice: StateSlice<T> = state[sliceConfiguration.name];
  const entities =
    ids
      ?.map((id) => ({ ...slice.byId[id]?.data, id } as T))
      .filter((item: any) => item) || [];
  return entities;
}

export function getStateDraftMetas<T extends WithId>(
  state: any,
  sliceConfiguration: SliceConfiguration,
) {
  const slice: StateSlice<T> = state[sliceConfiguration.name];
  const { error, meta, data = null } = (slice.drafts && slice.drafts[0]) || {};

  return { status: StoreStatus.Draft, data, meta, error };
}
