import { createSlice } from '@reduxjs/toolkit'
import { OrderingPractitioner } from 'models';
import { createInitialSlice } from 'store/utils';
import { createItemAsyncThunk, createListAsyncThunk, GenericItemSelectorResult, GenericListSelectorResult, getStateItemMetas, getStateListEntities, getStateListMetas } from 'store/factory';
import { getOrderingPractitionersByAgencyAsync, ListByAgencyGroupAndAgencyArgs } from 'services/agency-groups';
import { getOrderingPractitionerByIdAsync, OrderingPractitionerByIdArgs } from 'services/ordering-practitioners-service';

// DEFINITION
const sliceConfiguration = {
  name: 'orderingPractitioners',
};
const initialState = createInitialSlice<OrderingPractitioner>();
const listNameByAgency = ({ agencyCode }: Omit<ListByAgencyGroupAndAgencyArgs, 'agencyGroupId'>) => `angencies/${agencyCode}`;

// THUNKS
const fetchOrderingPractitionerById = createItemAsyncThunk<OrderingPractitioner, OrderingPractitionerByIdArgs>(getOrderingPractitionerByIdAsync, { ...sliceConfiguration, type: 'fetchOrderingPractitionerById' });
const fetchOrderingPractitionersByAgency = createListAsyncThunk<OrderingPractitioner, ListByAgencyGroupAndAgencyArgs>(
  getOrderingPractitionersByAgencyAsync,
  { 
    ...sliceConfiguration, 
    type: 'fetchOrderingPractitionersByAgency',
    listNameFormatter: listNameByAgency,
  },
);
export { fetchOrderingPractitionerById, fetchOrderingPractitionersByAgency };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchOrderingPractitionerById.addCasesTo?.(builder);
    fetchOrderingPractitionersByAgency.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getOrderingPractitionersByAgency = (agencyCode?: number) => (state: any): GenericListSelectorResult<OrderingPractitioner> => {
  if (!!agencyCode) {
    const listName = listNameByAgency({ agencyCode });
    const { status, ids, meta, error } = getStateListMetas<OrderingPractitioner>(state, listName, sliceConfiguration);
    const entities = getStateListEntities<OrderingPractitioner>(state, ids, sliceConfiguration);
    return [entities, { ...meta, status }, error];
  } else {
    return [[], {}, {}];
  }
};
export const getOrderingPractitionerById = (id?: number) => (state: any): GenericItemSelectorResult<OrderingPractitioner> => {
  if (typeof id !== 'undefined') {
    const {
      status,
      error,
      meta,
      data: item = {} as OrderingPractitioner,
    } = getStateItemMetas<OrderingPractitioner>(state, id, sliceConfiguration);

    return [item, { ...meta, status }, error];
  } else {
    return [null, {}, {}];
  }
};