import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collection, Agency, SpecimenType } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  createListAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getStateDraftMetas,
  getStateListEntities,
  getStateListMetas,
  patchDraftItem,
  putDraftItem,
} from 'store/factory';
import { FetchListArgs, StateSlice, StoreStatus } from 'store/types';
import { getAgencies } from './agencies';
import { SpecimenTypesDefaultCatalog } from './specimen-types';
import {
  CollectionListArgs,
  getCollectedCollectionsAsync,
  getCollectedCollectionsTodayAsync,
} from 'services/collections-service';

// DEFINITION
const sliceConfiguration = {
  name: 'collections',
};
const initialState = createInitialSlice<Collection>();

const listNameCollectedCollectionsTodayByDonor = ({
  donorId,
}: CollectionListArgs) => `collections/${donorId}/today`;
const listNameCollectedCollectionsByRequisitionNumber = ({
  requisitionNumber,
}: CollectionListArgs) => `collections/${requisitionNumber}`;

// THUNKS
const fetchCollectedCollectionsTodayByDonor = createListAsyncThunk<
  Collection,
  CollectionListArgs & FetchListArgs
>(getCollectedCollectionsTodayAsync, {
  ...sliceConfiguration,
  type: 'fetchCollectedCollectionsToday',
  listNameFormatter: listNameCollectedCollectionsTodayByDonor,
});

const fetchCollectedCollectionsByRequisitionNumber = createListAsyncThunk<
  Collection,
  CollectionListArgs & FetchListArgs
>(getCollectedCollectionsAsync, {
  ...sliceConfiguration,
  type: 'fetchCollectedCollectionsByRequisitionNumber',
  listNameFormatter: listNameCollectedCollectionsByRequisitionNumber,
});

export {
  fetchCollectedCollectionsTodayByDonor,
  fetchCollectedCollectionsByRequisitionNumber,
};

// SLICE
const collectionsSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {
    patchCurrentCollection: (
      state: StateSlice<Collection>,
      { payload }: PayloadAction<Partial<Collection>>,
    ) => patchDraftItem(state, payload),
    setCurrentCollection: (
      state: StateSlice<Collection>,
      { payload }: PayloadAction<Partial<Collection>>,
    ) => putDraftItem(state, payload),
    clearCurrentCollection: (state: StateSlice<Collection>) =>
      putDraftItem(state, null),
  },
  extraReducers: (builder) => {
    fetchCollectedCollectionsTodayByDonor.addCasesTo(builder);
    fetchCollectedCollectionsByRequisitionNumber.addCasesTo(builder);
  },
});

// Action creators are generated for each case reducer function
export const {
  patchCurrentCollection,
  clearCurrentCollection,
  setCurrentCollection,
} = collectionsSlice.actions;
export default collectionsSlice.reducer;

// SELECTORS
export const getCurrentCollection = (
  state: any,
): GenericItemSelectorResult<Partial<Collection>> => {
  const { meta, data, error } = getStateDraftMetas<Collection>(
    state,
    sliceConfiguration,
  );

  return [
    data,
    {
      ...meta,
      status: data === null ? StoreStatus.NotFound : StoreStatus.Draft,
    },
    error,
  ];
};

export const getColletedCollectionsTodayByDonor =
  (donorId: number) =>
  (state: any): GenericListSelectorResult<Partial<Collection>> => {
    if (donorId) {
      const listName = listNameCollectedCollectionsTodayByDonor({ donorId });
      const { status, ids, meta, error } = getStateListMetas<Collection>(
        state,
        listName,
        sliceConfiguration,
      );
      const entities = getStateListEntities<Collection>(
        state,
        ids,
        sliceConfiguration,
      );
      return [entities, { ...meta, status }, error];
    } else {
      return [[], {}, {}];
    }
  };

export const getColletedCollectionByRequisitionNumber =
  (requisitionNumber: string) =>
  (state: any): GenericItemSelectorResult<Partial<Collection>> => {
    if (requisitionNumber) {
      const listName = listNameCollectedCollectionsByRequisitionNumber({
        requisitionNumber,
      });
      const { status, ids, meta, error } = getStateListMetas<Collection>(
        state,
        listName,
        sliceConfiguration,
      );
      const entities = getStateListEntities<Collection>(
        state,
        ids,
        sliceConfiguration,
      );
      return [entities[0], { ...meta, status }, error];
    } else {
      return [null, {}, {}];
    }
  };

export const getSelectedAgency = (state: any): [Agency] => {
  const [collection] = getCurrentCollection(state);
  const [agencies] = getAgencies(state);
  const selectedAgency = agencies.find(
    (elem: Agency) => elem.code === collection?.agencyId,
  );
  return [selectedAgency as Agency];
};

export const getSelectedSpecimenType = (state: any): [SpecimenType] => {
  const [collection] = getCurrentCollection(state);
  const selectedSpecimenType = SpecimenTypesDefaultCatalog.find(
    (elem: SpecimenType) => elem.id === collection?.specimenType,
  );
  return [selectedSpecimenType as SpecimenType];
};

export const getSelectedTests = (state: any): [Collection['tests']] => {
  const [collection] = getCurrentCollection(state);
  return [collection?.tests as Collection['tests']];
};
