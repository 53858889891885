import { apiClient } from "clients";
import { env } from "env";
import { PendingSpecimen, SpecimenTypes, PendingSpecimenStatus } from "models";
import { handleFetchError } from "utils/error-utils";
import { FetchByIdArgs, FetchListArgs, GenericListResult, PaginationArgs } from "./types";


export interface PendingSpecimenListResult extends GenericListResult<PendingSpecimen> { }
export interface PendingSpecimenByIdArgs extends FetchByIdArgs<string> { }
export interface PendingSpecimenListArgs extends FetchListArgs, PaginationArgs {
  donorId?: number;
}

function normalizedResults(items: any[]): PendingSpecimen[] {

  return items.map(({
    donorId,
    firstName,
    middleName,
    lastName,
    uniqueId,
    agencyName,
    agencyCode,
    collectedSpecimenId,
    specimenType,
    requisitionNumber,
    accessionNum,
    identification,
    collectedDate,
    receivedDate,
    collector,
    observed,
    status,
  }: any) => ({
    id: requisitionNumber,
    specimenType: Object.values(SpecimenTypes).includes(specimenType?.toLowerCase()) ? specimenType?.toLowerCase() : SpecimenTypes.Unknown,
    requisitionNumber,
    identification,
    accessionNum,
    collector,
    collectedDate,
    collectedSpecimenId,
    receivedDate,
    observed: !!observed,
    donor: {
      id: donorId,
      firstName,
      middleName,
      lastName,
      uniqueId,
      agency: {
        id: agencyCode,
        name: agencyName,
        code: agencyCode,
      },
    },
    status: PendingSpecimenStatus[status as keyof typeof PendingSpecimenStatus],

  }));

}

export async function getPendingSpecimensAsync(args: PendingSpecimenListArgs): Promise<PendingSpecimenListResult> {

  try {
    const { data } = await apiClient.get('/Collections/PendingSpecimens', {
      params: {
        searchFilter: args.filter?.toLowerCase(),
        page: args.page || 1,
        rowsPerPage: args.pageSize || env.REACT_APP_ITEMS_PER_PAGE,
        ...(!!args.donorId && {
          donorId: args.donorId,
        }),
      }
    });
    const {
      pendingSpecimensList,
      page,
      rowsPerPage: pageSize,
      totalOfRows: totalCount,
    } = data;

    const items = normalizedResults(pendingSpecimensList);

    return {
      items,
      meta: {
        page,
        pageSize,
        totalCount,
      }
    };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getPendingSpecimenByIdAsync(arg: PendingSpecimenByIdArgs) {
  try {

    const { data: { pendingSpecimensList: pendingSpecimen } } = await apiClient.get('/Collections/PendingSpecimens', { params: { requisitionNumber: arg.id, page: 1, rowsPerPage: 25, } });

    if (!!pendingSpecimen && pendingSpecimen.length > 0) {

      const [item] = normalizedResults(pendingSpecimen);
      return item;

    } else {
      return null;
    }

  }
  catch (error: any) {
    throw handleFetchError(error);
  }
}
