import { localStorageClient } from '../../clients/local-storage-client';
import jwtDecode from 'jwt-decode';
import { AuthTokenPayload } from './types';

const TOKEN_KEY = 'auth:token';
const USERNAME_KEY = 'auth:username';

export async function getToken(): Promise<string | null> {
  const token = await localStorageClient.getItem<string>(TOKEN_KEY);
  return token;
}

export async function setToken(token: string): Promise<void> {
  await localStorageClient.setItem<string>(TOKEN_KEY, token);
}

export async function clearToken(): Promise<void> {
  await localStorageClient.removeItem(TOKEN_KEY);
}

export async function getUsername(): Promise<string | null> {
  const username = await localStorageClient.getItem<string>(USERNAME_KEY);
  return username;
}

export async function setUsername(username: string): Promise<void> {
  await localStorageClient.setItem<string>(USERNAME_KEY, username);
}

export async function clearUsername(): Promise<void> {
  await localStorageClient.removeItem(USERNAME_KEY);
}

export function validateToken() {}

export function decodeToken(token: string): AuthTokenPayload {
  const decodedToken = jwtDecode<any>(token);

  const normalizedRole = (
    decodedToken[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ] || ''
  )
    .toLowerCase()
    .replace(/\s/gi, '_');

  return {
    username:
      decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ],
    role: normalizedRole,
    isCollector: decodedToken['IsCollector'] === 'True',
    hasResultAccess: decodedToken['HasResultAccess'] === 'True',
    hasCollectionsModule: decodedToken['HasCollectionsModule'] === 'True',
    hasTemporaryPassword: decodedToken['HasTemporaryPassword'] === 'True',
    hasDonorEditAccess: decodedToken['HasDonorEditAccess'] === 'True',
    exp: decodedToken['exp'],
  };
}
