import { SignatureCanvas } from 'components';
import { Test } from 'models';

export interface CollectionSite {
  id: number;
  name: string;
}

export interface CollectionType {
  id: number;
  name: string;
  active: boolean;
}

export interface Signature {
  clientCreatedDatetime: string;
  source?: 'Mobile';
  // donorId?: number;
  content: string; //base64
}

export enum OnSiteDeviceResultsEnum {
  'Negative' = 0,
  'Positive' = 1,
  'Invalid' = 2,
}

export type OnSiteDeviceResultsValues = 'Negative' | 'Positive' | 'Invalid';

export interface Collection {
  id: string;
  donorId: number;
  collectedDate?: string;
  collectedTime?: string;
  requisitionNumber?: string;
  uniqueId?: string;

  agencyId?: number;
  agencyNumber?: number;
  agencyGroupId?: number;
  agencyName?: string;
  agencyAddress1?: string;
  agencyAddress2?: string;
  agencyAddress3?: string;
  agencyCity?: string;
  agencyState?: string;
  agencyZipCode?: string;
  agencyPhone?: string;

  collectionSiteId?: number;

  collectionType?: number;
  collectionTypeName?: string;

  observed?: boolean;

  orderingPractitionerId: number;

  specimenType?: number;
  specimenTypeName?: string;
  temperature?: string;

  collectorFirstName?: string;
  collectorLastName?: string;

  donorFirstName?: string;
  donorMiddleName?: string;
  donorLastName?: string;
  donorBirthday?: string;
  donorSex?: 'F' | 'M';
  donorPhone?: string;

  signature?: Signature;
  tests: (Pick<Test, 'code'> & Partial<Omit<Test, 'code'>>)[];
  isScheduledCollection: boolean;
  scheduledCollectionDetail: {
    scheduledId: number;
    source: 'None' | 'Donor' | 'DonorGroup' | 'AgencyGroup';
    collectionType: 'None' | 'Onetime' | 'Random';
    isOnsite: boolean;
  }[];

  hasSignature?: boolean;
  source: 'MOBILE' | 'TA';

  typeOfTest: 'Lab' | 'OnSite' | undefined;

  // OnSite fields
  hasAdditionalTest: boolean;
  // Use only when is onsite
  deviceId?: number;
  deviceResults?: {
    code: string;
    result: OnSiteDeviceResultsValues;
  }[];
  //

  specimenId?: string;
  collectorRemarks?: string;
  collectorId?: number;

  donorSignature?: SignatureCanvas;
  collectorSignature?: SignatureCanvas;
}
