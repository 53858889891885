import { createSlice } from '@reduxjs/toolkit'
import { Agency } from 'models';
import { createInitialSlice } from 'store/utils';
import { createListAsyncThunk, GenericListSelectorResult, getStateListEntities, getStateListMetas } from 'store/factory';
import { getAgenciesAsync, ListByAgencyGroupArgs } from 'services/agency-groups';

// DEFINITION
const sliceConfiguration = {
  name: 'agencies',
};
const initialState = createInitialSlice<Agency>();

// THUNKS
const fetchAgencies = createListAsyncThunk<Agency, ListByAgencyGroupArgs>(getAgenciesAsync, { ...sliceConfiguration, type: 'fetchAgencies' });

export { fetchAgencies };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchAgencies.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getAgencies = (state: any): GenericListSelectorResult<Agency> => {
  const { status, ids, meta, error } = getStateListMetas<Agency>(state, 'all', sliceConfiguration);
  const entities = getStateListEntities<Agency>(state, ids, sliceConfiguration);
  return [entities, { ...meta, status }, error];
};