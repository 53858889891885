import { createSlice } from '@reduxjs/toolkit';
import { ScheduledCollection } from 'models';
import { getListNameFor } from 'store/utils';
import {
  createListAsyncThunk,
  GenericListSelectorResult,
  getStateListEntities,
  getStateListMetas,
  createInitialSlice,
} from 'store/factory';
import {
  getScheduledCollectionsAsync,
  ScheduledCollectioListArgs,
  ScheduledCollectionMetaData,
} from 'services/scheduled-collections-service';

// DEFINITION
const sliceConfiguration = {
  name: 'scheduledCollections',
};
const initialState = createInitialSlice<
  ScheduledCollection,
  ScheduledCollectionMetaData
>();

// THUNKS
const fetchScheduledCollections = createListAsyncThunk<
  ScheduledCollection,
  Omit<ScheduledCollectioListArgs, 'donorId'>
>(getScheduledCollectionsAsync, {
  ...sliceConfiguration,
  type: 'fetchScheduledCollections',
});

const fetchNextScheduledCollections = fetchScheduledCollections.next;
export { fetchScheduledCollections, fetchNextScheduledCollections };

// SLICE
const scheduledCollectionsSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchScheduledCollections.addCasesTo?.(builder);
  },
});

// Action creators are generated for each case reducer function
// export const { } = donorsSlice.actions
export default scheduledCollectionsSlice.reducer;

// SELECTORS
export const getAllScheduledCollections = (
  state: any,
): GenericListSelectorResult<
  ScheduledCollection,
  ScheduledCollectionMetaData
> => {
  const { status, ids, meta, error } = getStateListMetas<ScheduledCollection>(
    state,
    'all',
    sliceConfiguration,
  );
  const entities = getStateListEntities<ScheduledCollection>(
    state,
    ids,
    sliceConfiguration,
  );
  return [entities, { ...meta, status }, error];
};

export const getFilteredScheduledCollections =
  (filterTerm?: string) =>
  (
    state: any,
  ): GenericListSelectorResult<
    ScheduledCollection,
    ScheduledCollectionMetaData
  > => {
    const listName = getListNameFor({ filter: filterTerm });
    const { status, ids, meta, error } = getStateListMetas<ScheduledCollection>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<ScheduledCollection>(
      state,
      ids,
      sliceConfiguration,
    );
    return [entities, { ...meta, status }, error];
  };
