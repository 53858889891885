import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { setupStore } from './store';
import { Provider as ReduxProvider } from 'react-redux';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { env } from 'env';

const mwbScanner = require('cmbsdk-cmbweb');
window.mwbScanner = mwbScanner;

/* istanbul ignore next */
if (env.REACT_APP_GOOGLE_TAG_MANAGER) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: env.REACT_APP_GOOGLE_TAG_MANAGER,
  };

  TagManager.initialize(tagManagerArgs);
}

const doDeviceRedirection =
  (env.REACT_APP_AVOID_DEVICE_REDIRECTION || '') !== 'true';

/* istanbul ignore next */
if (doDeviceRedirection) {
  window.RedirectToLegacyIfDesktop(env.REACT_APP_TA_DESKTOP_URL);
  if (window.location.hostname === env.REACT_APP_REDIRECT_DOMAIN) {
    window.location.href = env.REACT_APP_REDIRECT_DOMAIN_TO;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={setupStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
