import { createSlice } from '@reduxjs/toolkit';
import { AgencySettings } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  createItemAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getStateItemMetas,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';
import {
  getAgencyGroupSettingsAsync,
  AgencyGroupSettingsArgs,
} from 'services/agency-groups';

// DEFINITION
const sliceConfiguration = {
  name: 'agencyGroupSettings',
};
const initialState = createInitialSlice<AgencySettings>();

// THUNKS
const fetchAgencyGroupSettings = createItemAsyncThunk<
  AgencySettings,
  AgencyGroupSettingsArgs
>(getAgencyGroupSettingsAsync, {
  ...sliceConfiguration,
  type: 'fetchAgencyGroupSettings',
});

export { fetchAgencyGroupSettings };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchAgencyGroupSettings.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getAgencyGroupSettings =
  (id: string) =>
  (state: any): GenericItemSelectorResult<AgencySettings> => {
    const { data, status, meta, error } = getStateItemMetas<AgencySettings>(
      state,
      id,
      sliceConfiguration,
    );
    return [data, { ...meta, status }, error];
  };
