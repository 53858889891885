import { createSlice } from '@reduxjs/toolkit';
import { Collector } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  createListAsyncThunk,
  GenericListSelectorResult,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';
import {
  getCollectorsByIdAsync,
  CollectorsByIdArgs,
} from 'services/collectors-service';

// DEFINITION
const sliceConfiguration = {
  name: 'collectors',
};
const initialState = createInitialSlice<Collector>();

// THUNKS
const fetchCollectors = createListAsyncThunk<Collector, CollectorsByIdArgs>(
  getCollectorsByIdAsync,
  { ...sliceConfiguration, type: 'fetchCollectors' },
);

export { fetchCollectors };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchCollectors.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getCollectors = (
  state: any,
): GenericListSelectorResult<Collector> => {
  const { status, ids, meta, error } = getStateListMetas<Collector>(
    state,
    'all',
    sliceConfiguration,
  );
  const entities = getStateListEntities<Collector>(
    state,
    ids,
    sliceConfiguration,
  );
  return [entities, { ...meta, status }, error];
};
