import { AsyncThunk, SerializedError } from '@reduxjs/toolkit';

export interface WithId {
  id: number | string;
}

export enum StoreStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succees = 'success',
  Failure = 'failure',
  Deleted = 'deleted',
  NotFound = 'notfound',
  Draft = 'draft',
}

export interface FetchByIdArgs<T> {
  id: T;
}

export interface FetchListArgs {
  list?: string;
  filter?: string;
  forceLoad?: boolean;
}

export interface PaginationArgs {
  page?: number;
  pageSize?: number;
}

export interface EntityState<T, M extends MetaData = MetaData> {
  status: StoreStatus;
  data: T;
  error?: SerializedError;
  meta?: MetaData & M;
}
export interface ListState<T extends WithId, M extends MetaData = MetaData> {
  status: StoreStatus;
  ids: Array<T['id']>;
  error?: SerializedError;
  meta?: MetaData & M;
}

export interface StateSlice<T extends WithId, M extends MetaData = MetaData> {
  drafts: EntityState<Partial<T>, M>[];
  byId: Record<T['id'], EntityState<T, M>>;
  byList: Record<string, ListState<T, M>>;
}

export interface PaginationMetaData {
  pageSize?: number;
  page?: number;
  totalCount?: number;
}

export interface MetaData extends PaginationMetaData {
  status?: StoreStatus;
}

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

export type PendingAction<Returned, ThunkArg> = ReturnType<
  AsyncThunk<Returned, ThunkArg, any>['pending']
>;
export type RejectedAction<Returned, ThunkArg> = ReturnType<
  AsyncThunk<Returned, ThunkArg, any>['rejected']
>;
export type FulfilledAction<Returned, ThunkArg> = ReturnType<
  AsyncThunk<Returned, ThunkArg, any>['fulfilled']
>;
