import { apiClient } from "clients";
import { OrderingPractitioner } from "models";
import { handleFetchError } from "utils/error-utils";
import { FetchByIdArgs } from "./types";

export interface OrderingPractitionerByIdArgs extends FetchByIdArgs<number> {
  agencyGroupId: number;
  agencyCode: number;
}

export async function getOrderingPractitionerByIdAsync({ agencyCode, agencyGroupId, id: orderingPractitionerId }: OrderingPractitionerByIdArgs): Promise<OrderingPractitioner | null> {
  
  try {

    const { data } = await apiClient.get(`AgencyGroups/${agencyGroupId}/Agencies/${agencyCode}/OrderingPractitioners/${orderingPractitionerId}`);

    //   "orderingPractitionerId": 1463,
    //   "prefix": "",
    //   "firstName": "DAVID",
    //   "lastName": "MONGE PRACTITIONER",
    //   "suffix": "",
    //   "npi": "NPI123",
    //   "addressLine1": "AD123",
    //   "addressLine2": "",
    //   "city": "GDL",
    //   "state": "LA",
    //   "zip": "12345",
    //   "phoneNumber": "123-123-1234",
    //   "phoneNumberExt": "",
    //   "faxNumber": "",
    //   "agencyGroupId": 500012,
    //   "username": "allaccess",
    //   "usesElectronicAuthorization": true,
    //   "isActive": true,
    //   "createdBy": "Allaccess",
    //   "createdStamp": "2019-05-30T23:26:11.037",
    //   "modifedBy": "Allaccess",
    //   "modifiedStamp": "2019-05-30T23:26:11.037"

    const {
      orderingPractitionerId: id,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      phoneNumber: phone,
      npi,
    } = data.data;

    const name = `${firstName}${!!lastName.length ? ` ${lastName}` : ''}`;
    let fullAddress = "";

    if (addressLine1 !== "") {
      fullAddress = addressLine1;
    }

    if (addressLine2 !== "") {
      fullAddress = (addressLine1 === "") ? addressLine2 : addressLine1 + " " + addressLine2; 
    }

    if (fullAddress !== ""){
      fullAddress = fullAddress + ", ";
    }

    if (city !== "") {
        fullAddress = fullAddress + " " + city;
    }
    
    if (state !== "") {
      fullAddress = fullAddress + " " + state;
    }
    
    if (zip !== "" )  {
        fullAddress = fullAddress + " " + zip;
    }

    const address = fullAddress;

    return {
      id,
      name,
      address,
      phone,
      npi,
    };

  } catch (error: any) {
    throw handleFetchError (error);
  }

}