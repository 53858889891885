import { CircularProgress, Stack } from '@mui/material';

interface LoadingLayerProps {
  size?: number;
  text?: string;
}

export function LoadingLayer({ size = 60, text }: LoadingLayerProps) {
  return (
    <Stack
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: 'abbott.white',
        zIndex: 9999,
      }}
    >
      <CircularProgress color="primary" size={size} />
      {text && <div>{text}</div>}
    </Stack>
  );
}
