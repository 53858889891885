import { Donor } from "./donors";
import { SpecimenTypes } from "./results";

export enum PendingSpecimenStatus {
  SHIP = 'shipped',
  COLL = 'collected',
  RCVD = 'received',
}

export interface PendingSpecimen {
  id: string; // RequisitionNumber
  specimenType: SpecimenTypes;
  identification: string;
  accessionNum: string;
  collector?: string;
  collectedDate?: string;
  collectedSpecimenId?: string,
  receivedDate?: string;
  observed?: boolean;
  donor: Donor;
  status?: string;
} 