import { lazy, Suspense, useCallback, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import AbbottTheme from './arc-components/theme-provider/theme';
import { env } from 'env';
import { LoadingLayer } from 'components/LoadingLayer';

import './App.css';

const maintenanceMode = env.REACT_APP_SITE_UNDER_MAINTENANCE === 'true';

const MaintenancePage = lazy(() => import('pages/content/Maintenance'));
const AppMain = lazy(() => import('./App.Main'));

function App() {
  const calculateAppHeight = useCallback(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }, []);

  useEffect(() => {
    if (!!env.REACT_APP_TITLE?.length) {
      document.title = env.REACT_APP_TITLE;
    }

    calculateAppHeight();

    // Set app-height css var when resize happens
    window.addEventListener('resize', calculateAppHeight);
    return () => {
      window.removeEventListener('resize', calculateAppHeight);
    };
  }, []);

  const theme = createTheme(AbbottTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<LoadingLayer />}>
        {maintenanceMode ? <MaintenancePage /> : <AppMain />}
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
