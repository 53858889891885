import { createSlice } from '@reduxjs/toolkit'
import { SpecimenType } from 'models';
import { createInitialSlice } from 'store/utils';
import { createListAsyncThunk, GenericListSelectorResult, getStateListEntities, getStateListMetas } from 'store/factory';
import { ListByAgencyGroupAndAgencyArgs, getSpecimenTypesByAgencyAsync } from 'services/agency-groups';

// DEFINITION
const sliceConfiguration = {
  name: 'specimenTypes',
};
const initialState = createInitialSlice<SpecimenType>();
const listNameByAgency = ({ agencyCode }: Omit<ListByAgencyGroupAndAgencyArgs, 'agencyGroupId'>) => `angencies/${agencyCode}`;

// THUNKS
const fetchSpecimenTypesByAgency = createListAsyncThunk<SpecimenType, ListByAgencyGroupAndAgencyArgs>(
  getSpecimenTypesByAgencyAsync,
  { 
    ...sliceConfiguration, 
    type: 'fetchSpecimenTypesByAgency',
    listNameFormatter: listNameByAgency,
  },
);
export { fetchSpecimenTypesByAgency };

// SLICE
const slice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchSpecimenTypesByAgency.addCasesTo?.(builder);
  },
});

export default slice.reducer;

// SELECTORS
export const getSpecimenTypesByAgency = (agencyCode?: number) => (state: any): GenericListSelectorResult<SpecimenType> => {
  if (!!agencyCode) {
    const listName = listNameByAgency({ agencyCode });
    const { status, ids, meta, error } = getStateListMetas<SpecimenType>(state, listName, sliceConfiguration);
    const entities = getStateListEntities<SpecimenType>(state, ids, sliceConfiguration);
    return [entities, { ...meta, status }, error];
  } else {
    return [[], {}, {}];
  }
};

export const SpecimenTypesDefaultCatalog: SpecimenType[] = [
  {
    id: 1,
    description: 'Urine',
  },
  {
    id: 2,
    description: 'Oral',
  },
  {
    id: 3,
    description: 'Serum',
  },
];