import { createSlice } from '@reduxjs/toolkit';
import { Result } from 'models';
import { getListNameFor } from 'store/utils';
import {
  getResultByIdAsync,
  getResultsAsync,
  ResultByIdArgs,
  ResultsListArgs,
} from 'services/results-service';
import {
  createInitialSlice,
  createItemAsyncThunk,
  createListAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getStateItemMetas,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';
import { FetchListArgs, PaginationArgs } from 'services/types';

// DEFINITION
const sliceConfiguration = {
  name: 'results',
};
const initialState = createInitialSlice<Result>();

// THUNKS
const fetchResultsBy = createItemAsyncThunk<Result, ResultByIdArgs>(
  getResultByIdAsync,
  { ...sliceConfiguration, type: 'fetchResultsBy' },
);

const fetchResults = createListAsyncThunk<
  Result,
  Omit<ResultsListArgs, 'donorId'>
>(getResultsAsync, { ...sliceConfiguration, type: 'fetchResults' });
const fetchNextResults = fetchResults.next;

const fetchDonorResults = createListAsyncThunk<
  Result,
  FetchListArgs & PaginationArgs & { donorId: number }
>(
  ({ donorId, page, pageSize }) => getResultsAsync({ donorId, page, pageSize }),
  {
    ...sliceConfiguration,
    type: 'fetchDonorResults',
    listNameFormatter: ({ donorId }) => `donors/${donorId}`,
  },
);
const fetchNextDonorResults = fetchDonorResults.next;

export {
  fetchDonorResults,
  fetchNextDonorResults,
  fetchResults,
  fetchNextResults,
  fetchResultsBy,
};

// SLICE
const resultsSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {
    resetDonorResults: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    fetchResultsBy.addCasesTo(builder);
    fetchResults.addCasesTo(builder);
    fetchDonorResults.addCasesTo(builder);
  },
});

export const { resetDonorResults } = resultsSlice.actions;

export default resultsSlice.reducer;

// SELECTORS
export const getFilteredResults =
  (filterTerm?: string) =>
  (state: any): GenericListSelectorResult<Result> => {
    const listName = getListNameFor({ filter: filterTerm });
    const { status, ids, meta, error } = getStateListMetas<Result>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<Result>(
      state,
      ids,
      sliceConfiguration,
    );
    return [entities, { ...meta, status }, error];
  };

export const getDonorResults =
  (donorId: number) =>
  (state: any): GenericListSelectorResult<Result> => {
    const listName = `donors/${donorId}`;
    const { status, ids, meta, error } = getStateListMetas<Result>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<Result>(
      state,
      ids,
      sliceConfiguration,
    );
    return [entities, { ...meta, status }, error];
  };

export const getResultsById =
  (id: string) =>
  (state: any): GenericItemSelectorResult<Result> => {
    const {
      status,
      error,
      meta,
      data = {} as Result,
    } = getStateItemMetas<Result>(state, id, sliceConfiguration);
    return [data, { ...meta, status }, error];
  };
