import { createSlice } from '@reduxjs/toolkit'
import { PendingSpecimen } from 'models';
import { getListNameFor } from 'store/utils';
import {
  getPendingSpecimensAsync,
  getPendingSpecimenByIdAsync,
  PendingSpecimenByIdArgs,
  PendingSpecimenListArgs
} from 'services/pending-specimens-service';
import {
  createInitialSlice,
  createItemAsyncThunk,
  createListAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getStateItemMetas,
  getStateListEntities,
  getStateListMetas
} from 'store/factory';
import { FetchListArgs, PaginationArgs } from 'services/types';

// DEFINITION
const sliceConfiguration = {
  name: 'pendingSpecimens',
};
const initialState = createInitialSlice<PendingSpecimen>();

// THUNKS
const fetchPendingSpecimensBy = createItemAsyncThunk<PendingSpecimen, PendingSpecimenByIdArgs>(getPendingSpecimenByIdAsync, { ...sliceConfiguration, type: 'fetchPendingSpecimensBy' });

const fetchPendingSpecimens = createListAsyncThunk<PendingSpecimen, PendingSpecimenListArgs>(getPendingSpecimensAsync, { ...sliceConfiguration, type: 'fetchPendingSpecimens' });
const fetchNextPendingSpecimens = fetchPendingSpecimens.next;

const fetchDonorPendingSpecimens = createListAsyncThunk<PendingSpecimen, FetchListArgs & PaginationArgs & { donorId: number; }>(
  ({ donorId, page, pageSize }) => getPendingSpecimensAsync({ donorId, page, pageSize }),
  {
    ...sliceConfiguration,
    type: 'fetchDonorPendingSpecimens',
    listNameFormatter: ({ donorId }) => `donors/${donorId}`,
  },
);
const fetchNextDonorPendingSpecimens = fetchDonorPendingSpecimens.next;

export { fetchDonorPendingSpecimens, fetchNextDonorPendingSpecimens, fetchPendingSpecimens, fetchNextPendingSpecimens, fetchPendingSpecimensBy };

// SLICE
const pendingSpecimensSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchPendingSpecimensBy.addCasesTo(builder);
    fetchPendingSpecimens.addCasesTo(builder);
    fetchDonorPendingSpecimens.addCasesTo(builder);
  }
})

// Action creators are generated for each case reducer function
// export const { } = donorsSlice.actions
export default pendingSpecimensSlice.reducer;

// SELECTORS
export const getFilteredPendingSpecimens = (filterTerm?: string) => (state: any): GenericListSelectorResult<PendingSpecimen> => {
  const listName = getListNameFor({ filter: filterTerm });
  const { status, ids, meta, error } = getStateListMetas<PendingSpecimen>(state, listName, sliceConfiguration);
  const entities = getStateListEntities<PendingSpecimen>(state, ids, sliceConfiguration);
  return [entities, { ...meta, status }, error];
};

export const getDonorPendingSpecimens = (donorId: number) => (state: any): GenericListSelectorResult<PendingSpecimen> => {
  const listName = `donors/${donorId}`;
  const { status, ids, meta, error } = getStateListMetas<PendingSpecimen>(state, listName, sliceConfiguration);
  const entities = getStateListEntities<PendingSpecimen>(state, ids, sliceConfiguration);
  return [entities, { ...meta, status }, error];
};

export const getPendingSpecimensById = (id: string) => (state: any): GenericItemSelectorResult<PendingSpecimen> => {
  const { status, error, meta, data = {} as PendingSpecimen } = getStateItemMetas<PendingSpecimen>(state, id, sliceConfiguration);
  return [data, { ...meta, status }, error];
};
