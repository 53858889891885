import { createSlice } from '@reduxjs/toolkit'
import { User } from 'models';
import { createInitialSlice } from 'store/utils';
import { createItemAsyncThunk, GenericItemSelectorResult, getStateItemMetas } from 'store/factory';
import { getUserByIdAsync, UserByIdArgs } from 'services/users-service';


// DEFINITION
const sliceConfiguration = {
  name: 'users',
};
const initialState = createInitialSlice<User>();

// THUNKS
const fetchUserById = createItemAsyncThunk<User, UserByIdArgs>(
  getUserByIdAsync,
  { ...sliceConfiguration, type: 'fetchUserById' },
);
export { fetchUserById };

// SLICE
const usersSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchUserById.addCasesTo?.(builder);
  },
});

// Action creators are generated for each case reducer function
// export const { } = donorsSlice.actions
export default usersSlice.reducer;

// SELECTORS
export const getUserById = (id: string) => (state: any): GenericItemSelectorResult<User> => {
  const { status,  error, meta, data } = getStateItemMetas<User>(state, id, sliceConfiguration);
  return [data, { ...meta, status }, error];
};