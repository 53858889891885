import { apiClient } from 'clients';
import { CancellationReason } from 'models';
import { handleFetchError } from 'utils/error-utils';
import { FetchListArgs, GenericListResult } from './types';

export interface CancellationReasonsByIdArgs extends FetchListArgs {
  agencyGroupId: string;
}

export async function getCancellationReasonsByIdAsync({
  agencyGroupId,
}: CancellationReasonsByIdArgs): Promise<
  GenericListResult<CancellationReason>
> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/CancellationTypes/`,
    );

    let items: CancellationReason[] =
      data?.data?.map(
        (cancellation: {
          cancellationTypeId: number;
          cancellationType: string;
        }) =>
          ({
            id: cancellation.cancellationTypeId,
            reason: cancellation.cancellationType,
          } as CancellationReason),
      ) || [];

    items = items.sort(function (a, b) {
      if (a.reason.toLocaleLowerCase() < b.reason.toLocaleLowerCase()) {
        return -1;
      }
      if (a.reason.toLocaleLowerCase() > b.reason.toLocaleLowerCase()) {
        return 1;
      }
      return 0;
    });

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
