import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IError } from 'models';
import { generateUUID } from 'utils/uuid-utils';

const initialState: IError[] = [];

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<IError>) => {
      const error = action.payload;
      const id = generateUUID();
      state.push({...error, id });
    },
    removeError: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const index = state.findIndex((e) => {
        return e.id === id;
      });
      if (index !== -1) state.splice(index, 1);
    },
    removeErrorByCode: (state, action: PayloadAction<string>) => {
      const code = action.payload;
      return state.filter(error => error.code !== code);
    }
  },
})

// Action creators are generated for each case reducer function
export const { addError, removeError, removeErrorByCode } = errorsSlice.actions;

export default errorsSlice.reducer
export * from './selectors';
export { normalizeError } from 'utils/error-utils';