import { createSlice } from '@reduxjs/toolkit';
import { Donor } from 'models';
import { createInitialSlice } from 'store/utils';
import {
  DonorByIdArgs,
  DonorListArgs,
  getDonorByIdAsync,
  getDonorsAsync,
} from 'services/donors-service';
import {
  createItemAsyncThunk,
  createListAsyncThunk,
  GenericItemSelectorResult,
  GenericListSelectorResult,
  getListNameFor,
  getStateItemMetas,
  getStateListEntities,
  getStateListMetas,
} from 'store/factory';

// DEFINITION
const sliceConfiguration = {
  name: 'donors',
};
const initialState = createInitialSlice<Donor>();

// THUNKS
const fetchDonorBy = createItemAsyncThunk<Donor, DonorByIdArgs>(
  getDonorByIdAsync,
  { ...sliceConfiguration, type: 'fetchDonorBy' },
);
const fetchDonors = createListAsyncThunk<Donor, DonorListArgs>(getDonorsAsync, {
  ...sliceConfiguration,
  type: 'fetchDonors',
});

const fetchNextDonors = fetchDonors.next;
export { fetchDonors, fetchNextDonors, fetchDonorBy };

// SLICE
const donorsSlice = createSlice({
  ...sliceConfiguration,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchDonorBy.addCasesTo?.(builder);
    fetchDonors.addCasesTo?.(builder);
  },
});

// Action creators are generated for each case reducer function
// export const { } = donorsSlice.actions
export default donorsSlice.reducer;

// SELECTORS
export const getFilteredDonors =
  (filterTerm: string) =>
  (state: any): GenericListSelectorResult<Donor> => {
    const listName = getListNameFor({ filter: filterTerm });
    const { status, ids, meta, error } = getStateListMetas<Donor>(
      state,
      listName,
      sliceConfiguration,
    );
    const entities = getStateListEntities<Donor>(
      state,
      ids,
      sliceConfiguration,
    );
    return [entities, { ...meta, status }, error];
  };

export const getDonorById =
  (id: number) =>
  (state: any): GenericItemSelectorResult<Donor> => {
    const {
      status,
      error,
      meta,
      data: item = {} as Donor,
    } = getStateItemMetas<Donor>(state, id, sliceConfiguration);

    const normalizedItem = {
      ...(!!item && item),
      ...(!!item.dateOfBirth && {
        dateOfBirth: new Date(item.dateOfBirth),
      }),
      ...(!!item.intakeDate && {
        intakeDate: new Date(item.intakeDate),
      }),
    };

    return [normalizedItem, { ...meta, status }, error];
  };
